import { ReceiveWallet } from "../styled";
import default_token_image from "../../../../../assets/Dashboard/Common/default-token-image.png";
import { shortenAddress } from "../../../../../utils/addressUser";
import { WalletListStyle } from "../../../../../components/Common/WalletButton/styled";
import { useSelector } from "react-redux";
import PickWalletItem from "./Item";

const PickWallet = ({
  item,
  nameChainTo,
  updateSelectedWalletAddress,
  lastItem,
  handleSwitch,
  setReceiveWallet,
  handleCatchPayWallet,
}: any) => {
  const connectedWallets = useSelector(
    (state: any) => state.accountSlice.referrals
  );

  return (
    <ReceiveWallet>
      <div className="heading-wallet">
        <h3 className="title-wallet">Your {item.name} Wallet</h3>
      </div>
      <div className="content-wallet">
        <div className="content-wallet-left">
          <div className="logo-wallet">
            {!item.logo ? (
              <img
                style={{
                  borderRadius: "50%",
                }}
                src={default_token_image}
                width={30}
                height={30}
                loading="lazy"
                alt="Your Wallet"
              />
            ) : (
              <img
                src={item.logo}
                width={30}
                height={30}
                loading="lazy"
                alt="Your Wallet"
              />
            )}
          </div>
          <div className="address-wallet">
            <p className="address">
              Address:{" "}
              <span>{item.address ? shortenAddress(item.address) : "--"}</span>
            </p>
          </div>
        </div>
      </div>
      <WalletListStyle>
        {connectedWallets?.filter((wallet: any) => wallet.type === item.type)
          .length > 0 ? (
          connectedWallets
            ?.filter((wallet: any) => wallet.type === item.type)
            .map((wallet: any, index: any) => {
              return (
                <PickWalletItem
                  key={index}
                  item={item}
                  wallet={wallet}
                  updateSelectedWalletAddress={updateSelectedWalletAddress}
                  lastItem={lastItem}
                  handleSwitch={handleSwitch}
                  setReceiveWallet={setReceiveWallet}
                  handleCatchPayWallet={handleCatchPayWallet}
                />
              );
            })
        ) : (
          <p
            style={{
              color: "#fff",
            }}
          >
            Please connect {nameChainTo.nameChainTo} wallet
          </p>
        )}
      </WalletListStyle>
    </ReceiveWallet>
  );
};

export default PickWallet;
