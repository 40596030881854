import styled from "styled-components";
import { SwapChartFilter, SwapHeader, SwapWrapper } from "../Swap/styled";
import { TitleContainer } from "../../components/Common/Title/styled";
import { SelectContainer } from "../../components/Common/Select/styled";
import { InputContainer } from "../../components/Common/Input/styled";

export const BridgeContainer = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  gap: 10px;
  margin-top: 16px;
  justify-content: center;
  width: 100%;
  ${SwapWrapper} {
    &.no-router {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ${TitleContainer} {
      margin-bottom: 15px;
    }
    &:nth-child(1) {
      max-width: 470px;
      width: 100%;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
  ${SwapHeader} {
    border-bottom: solid 1px #38383a;
    padding-bottom: 10px;
    margin-bottom: 14px;
  }
  &.bridge-container-left {
    max-width: 36%;
  }
  &.bridge-container-right {
    max-width: 68%;
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.26s ease;
    height: 486px;
    &.is-show {
      width: 100%;
      visibility: visible;
      opacity: 1;
    }
    ${SwapWrapper} {
      max-width: 100%;
      height: 432px;
      overflow: auto;
      &.light {
        background-color: #fff;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1130px) {
    &.bridge-container-left {
      max-width: 38%;
    }
    &.bridge-container-right {
      max-width: 62%;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    &.bridge-container-left {
      max-width: 100%;
      margin-bottom: 20px;
      ${SwapWrapper} {
        max-width: 518px;
      }
    }
    &.bridge-container-right {
      max-width: 552px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 767.98px) {
    &.bridge-container-left {
      max-width: 100%;
    }
    &.bridge-container-right {
      position: fixed;
      bottom: 100px;
      max-width: 93%;
      left: 50%;
      /* margin: 0 auto; */
      margin-left: -46.5%;
      transform: translateY(100%);
      transition: transform 0.26s ease;
      border-radius: 10.8px;
      border: 1.35px solid var(--Neutral-700, #4d4d57);
      background: #1c1c1e;
      box-shadow: 0px 0px 76.86px 0px rgba(106, 126, 228, 0.15);
      max-height: 50vh;
      height: 100%;
      overflow: auto;
      &.is-show {
        transform: translateY(0);
      }
      ${SwapWrapper} {
        overflow: auto;
        height: unset;
      }
    }
  }
`;
export const BridgeContainerInner = styled.div``;
export const BridgeForm = styled.div`
  h2 {
    font-family: Aspekta-500;
    font-size: 15px;
    color: #fff;
    margin-bottom: 5px;
  }
  .ant-checkbox-wrapper {
    span {
      &:last-child {
        color: #5e5e6b;
        font-size: 13px;
        line-height: 125%;
      }
    }
  }
`;
export const BridgeSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  & > p {
    color: #fff;
    font-family: Aspekta-500;
    font-size: 13px;
  }
  .ant-select {
    background-color: transparent;
    border-color: #4d4d57;
    .select-text {
      font-size: 13px;
      font-family: Aspekta-500;
    }
  }
  ${SelectContainer} {
    width: 50%;
  }
`;
export const BridgeInput = styled.div`
  border-radius: 8px;
  padding: 12px;
  &.bridge-input-to {
    .ant-input-disabled {
      color: #939393 !important;
    }
  }
  .ant-input-affix-wrapper {
    padding: 0;
    line-height: normal;
  }
  .ant-input-outlined {
    padding: 0;
    border-radius: unset;
    line-height: normal;
    height: 60px;
    font-size: 28px;
    color: #b5ebff;
    outline: unset;
    border: 0;
  }
  ${InputContainer} {
    width: 100%;
  }
  &.light {
    .ant-input-outlined {
      color: #007af5;
    }
  }
`;
export const BridgeSwitch = styled.div`
  position: relative;
  width: 29px;
  height: 29px;
  margin: 10px auto;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: rgba(153, 153, 153, 0.3);
    width: 162px;
    height: 1px;
    top: 50%;
  }
  &::before {
    left: 40px;
  }
  &::after {
    right: 40px;
  }
  img {
    width: 100%;
    height: auto;
  }
`;
export const BridgeSuffix = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: #92929e;
    font-family: Aspekta-500;
    font-size: 12px;
    span {
      margin-left: 5px;
    }
  }
  & > span {
    color: #007af5;
    font-family: Aspekta-600;
    font-size: 11px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.15s linear;
    &:hover {
      opacity: 0.7;
    }
  }
`;
export const BridgeInner = styled.div`
  border-bottom: 1px solid #383838;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;
export const BridgeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0;
    span {
      display: block;
      margin-top: 2px;
      color: #c8d2e0;
      font-family: Aspekta-400;
      font-size: 12px;
    }
  }
`;
export const BridgeTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
  h2 {
    color: #fff;
    font-family: Aspekta-400;
    font-size: 16px;
  }
  ${SwapChartFilter} {
    & > li {
      width: max-content;
      padding: 6px 12px;
      height: 22px;
    }
  }
`;
export const RouteText = styled.p`
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    padding: 4px;
    border-radius: 6px;
    background: #52565b;
  }
`;
export const BridgeRouteList = styled.ul`
  & > li {
    border-radius: 10px;
    overflow: hidden;
  }
`;

//CSS NEW
export const BridgeContentTable = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block;
  }
  @media screen and (max-width: 767.98px) {
    display: block;
  }
`;
export const BridgeContent = styled.div`
  border-bottom: solid 1px #38383a;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-family: "Rethink Sans";
  &:first-child {
    padding-top: 14px;
    margin-top: 9px;
    border-top: solid 1px #38383a;
  }
`;
export const BridgeContentInner = styled.div``;
export const BridgeContentHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .block-left {
    border-radius: 8px;
    background: #3f3f3f;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .is-address {
      padding: 0 8px;
      color: var(--Blue--Pri-200, #b5ebff);
      font-size: 15px;
      font-weight: 500;
    }
  }
  .arrow-address {
    transform: rotate(-90deg);
  }
  .block-right {
    display: flex;
    align-items: center;
  }
  .select-token {
    display: flex;
    align-items: center;
    border-radius: 8.571px;
    background: #2f2f2f;
    padding: 7px 6px 7px 8px;
    cursor: pointer;
    .no-icon {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      border: solid 1px #fff;
    }
    .logo-token {
      img {
        position: relative;
        top: 2px;
        border-radius: 50%;
      }
    }
  }
  .name-token {
    font-size: 14px;
    margin-left: 6px;
    margin-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .icon-arrow-token {
    border-radius: 5px;
    background: #1c1c1c;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 17px;
      height: 15px;
    }
  }
  .block-chain {
    margin-left: 4px;
    border-radius: 8.571px;
    border: 0.714px solid #484848;
    background: #2f2f2f;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 5.714px;
      background: #1c1c1c;
      padding: 5px;
    }
    .logo-token {
      height: 34px;
    }
    .no-icon {
      border: solid 1px #fff;
      border-radius: 50%;
      width: 17px;
      height: 17px;
    }
  }
  &.light {
    .block-left,
    .select-token,
    .block-chain {
      background-color: #eaeaea;
    }
    .block-chain {
      border-color: #eaeaea;
      img {
        background-color: #f3f3f3;
      }
    }
    .icon-arrow-token {
      background-color: #f3f3f3;
    }
    .block-left {
      p {
        color: #000;
      }
      .is-address {
        color: #007af5;
      }
    }
    .name-token {
      color: #000;
    }
    .select-token {
      .no-icon {
        border-color: #000;
      }
    }
    .block-chain {
      .no-icon {
        border-color: #000;
      }
    }
  }
`;
export const BridgeBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content-left {
    p {
      font-size: 15px;
      font-weight: 400;
      color: var(--Blue--Pri-200, #b5ebff);
      span {
        font-weight: 400;
        color: #fff;
      }
    }
  }
  .content-right {
    p {
      color: var(--Neutral-400, #92929e);
      font-size: 14px;
      .max {
        margin-left: 8px;
        border-radius: 19px;
        background: var(--Blue--Pri-200, #b5ebff);
        padding: 2px 8px;
        color: var(--Blue--Pri-700, #007af5);
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .ant-spin-dot-item {
      background-color: #0dd5b8;
    }
  }
  &.light {
    .content-left {
      p,
      span {
        color: #4a4a4a;
      }
    }
    .content-right {
      p {
        color: #92929e;
        .max {
          background-color: #0861c5;
          color: #83e1ff;
        }
      }
    }
  }
`;

export const BridgeContentRouter = styled.div`
  border-bottom: 1px solid #383838;
  padding-bottom: 10px;
  margin-bottom: 10px;
  .bridge-content-router-inner {
    font-family: "Rethink Sans";
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background: #3f3f3f;
    padding: 6px 6px 6px 16px;
  }
  .router-left {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .text-left {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    span {
      border-radius: 19px;
      background: var(--Blue--Pri-200, #b5ebff);
      padding: 2px 8px;
      color: var(--Blue--Pri-700, #007af5);
      font-size: 11px;
      font-weight: 600;
      margin-right: 4px;
      position: relative;
      top: -2px;
    }
  }
  .router-middle {
    border-radius: 8px;
    background: #1c1c1c;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .router-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .router-right-inner {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #5a5a5a;
    background: #5c5c5c;
    padding: 3px 5px 2px;
    gap: 8px;
  }
  .block-images {
    line-height: 1;
    img {
      position: relative;
      &:not(:first-child) {
        margin-left: -14px;
      }
      &:nth-child(2) {
        z-index: 1;
      }
      &:nth-child(3) {
        z-index: 2;
      }
    }
    .no-icon {
      width: 27px;
      height: 27px;
      border: solid 1px #fff;
      border-radius: 14px;
    }
  }
  .block-steps {
    p {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.36px;
    }
  }
  .block-time {
    border-radius: 19px;
    background: var(--Blue--Pri-200, #b5ebff);
    padding: 2px 8px;
    p {
      color: var(--Blue--Pri-700, #007af5);
      font-size: 11px;
      font-weight: 600;
    }
  }
  &.light {
    .bridge-content-router-inner {
      background-color: #f3f4f8;
    }
    .text-left {
      color: #000;
      span {
        background-color: #0861c5;
        color: #83e1ff;
      }
    }
    .router-right-inner {
      background-color: #fff;
      border-color: transparent;
    }
    .block-steps {
      p {
        color: #000;
      }
    }
    .block-time {
      background-color: #0861c5;
      p {
        color: #83e1ff;
      }
    }
    .block-images {
      .no-icon {
        border-color: #000;
      }
    }
  }
`;

export const HeadingRouter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-family: "Rethink Sans";
  .title-router {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 125%;
    &.is-flex {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .time-router {
    border-radius: 19px;
    background: #eee2c7;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #d59800;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.39px;
  }
  &.light {
    .title-router {
      color: #000;
    }
  }
`;
export const BlockRouter = styled.div`
  background-color: #3f3f3f;
  border-radius: 8px;
  padding: 13px 8px;
  font-family: "Rethink Sans";
  border: solid 1px transparent;
  transition: all 0.26s ease;
  cursor: pointer;
  &:hover {
    border: solid 1px #fff;
  }
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  &.is-border {
    border: solid 1px #fff;
  }
  .block-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #3b3b3c;
    padding-bottom: 10px;
  }
  .list-function {
    display: flex;
    align-items: center;
    gap: 4px;
    li {
      border-radius: 19px;
      background: #d6d6d6;
      padding: 2px 8px;
      font-size: 11px;
      font-weight: 500;
    }
  }
  .list-commend {
    display: flex;
    align-items: center;
    gap: 4px;
    li {
      border-radius: 19px;
      background: #e4c7ee;
      padding: 2px 8px;
      color: #8000a7;
      font-size: 11px;
      font-weight: 500;
      line-height: 125%;
      letter-spacing: -0.33px;
      &.tag-recommended {
        background: #c8eec7;
        color: #00a703;
      }
      &.tag-fastest {
        background: #c7d7ee;
        color: #007af5;
      }
      &.is-hight {
        background: #ffd8d8;
        color: #f50000;
      }
    }
  }
  &.light {
    background-color: #f3f4f8;
    &.is-border {
      border-color: #000;
    }
    .block-top {
      border-bottom-color: #fff;
    }
  }
  @media screen and (max-width: 767.98px) {
    .list-function {
      li {
        font-size: 8px;
        padding: 2px 6px;
      }
    }
    .list-commend {
      li {
        font-size: 8px;
        padding: 2px 6px;
      }
    }
  }
`;

export const BlockRouterInner = styled.div`
  display: flex;
  justify-content: space-between;
  .block-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #fff;
    width: 100%;
    gap: 10px;
    padding-left: 10px;
    margin-top: 7px;
    padding: 9px 0 6px 7px;
    &.ant-popover-open {
      border-radius: 6px;
      background: #575757;
    }
    &:last-child {
      width: 129%;
      padding-right: 7px;
    }
    &.is-half {
      .router-intermediary {
        &:before {
          background-image: url("/assets/images/bridge/icn_line_04.svg");
          height: 6px;
        }
      }
    }
    &.is-small {
      &:last-child {
        width: 144%;
      }
      .router-intermediary {
        &:before {
          background-image: url("/assets/images/bridge/icn_line_05.svg");
          height: 6px;
        }
      }
    }
    &.light {
      .router-right {
        .token {
          color: #000;
        }
        .price {
          color: #6b6b6b;
        }
      }
      .router-intermediary {
        border-bottom-color: #000;
        &:after {
          border-color: #000;
        }
      }
      &.ant-popover-open {
        background: #eaeaea;
      }
    }
  }
  .block-line-middle {
    width: 10%;
    position: relative;
    left: -0.5%;
    display: flex;
    img {
      width: 100%;
      height: auto;
    }
    &:last-child {
      display: none;
    }
  }

  .router-detail {
    .router-to {
      .router-logo {
        justify-content: flex-end;
      }
    }
    &.hidden-router-to {
      display: none;
    }
  }
  .router-logo {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .router-left {
    position: relative;
    img {
      border-radius: 40px;
    }
    .icon-small {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
  .router-right {
    span {
      display: block;
    }
    .token {
      font-size: 15px;
      font-weight: 600;
      line-height: 125%;
    }
    .price {
      font-size: 12px;
      line-height: 125%;
      color: #b6b6b6;
    }
  }
  .router-intermediary {
    position: relative;
    width: 100%;
    text-align: center;
    /* border-bottom: dashed 1px #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      position: absolute;
      content: "";
      bottom: -6px;
      left: 0;
      right: 0;
      background: url("/assets/images/bridge/icn_line_02.svg") center / contain
        no-repeat;
      width: 100%;
      height: 10px;
    }
    /* &.router-intermediary-last {
      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        right: -3px;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 6px;
        height: 6px;
        border-top-width: 2px;
        border-top-style: solid;
        border-right-width: 2px;
        border-right-style: solid;
        border-color: #fff;
      }
    } */
    .icon-line {
      width: 85%;
      height: auto;
    }
    .icon-logo {
      position: relative;
      border-radius: 50%;
      width: auto;
      height: 18px;
      top: 1px;
      &:nth-child(2) {
        left: -7px;
        z-index: 1;
      }
      &:nth-child(3) {
        left: -12px;
        z-index: 2;
      }
    }
  }
  @media screen and (max-width: 767.98px) {
    .block-bottom {
      gap: 5px;
      align-items: center;
      &:last-child {
        padding-left: 5px;
      }
      &.is-full {
        padding-left: 0;
        .router-intermediary {
          .icon-line {
            width: 20px;
          }
        }
      }
    }
    .block-line-middle {
      width: 8%;
      justify-content: center;
      left: -0.9%;
      img {
        width: 20px;
      }
    }
    .router-left {
      img {
        width: 24px;
        height: 24px;
      }
      .icon-small {
        width: 12px;
        height: 12px;
        bottom: 3px;
        right: -3px;
      }
    }
    .router-right {
      flex-shrink: 0;
      .token {
        font-size: 10px;
      }
      .price {
        font-size: 9px;
      }
    }
    .router-intermediary {
      text-align: center;
      &:before {
        content: none !important;
      }
      &.router-intermediary-last {
        &:after {
          width: 4px;
          height: 4px;
          bottom: -6px;
        }
      }
      .icon-line {
        width: 20px;
        display: none;
      }
    }
  }
`;

export const NoFoundRouter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .no-image {
    margin-bottom: 5px;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 125%;
  }
`;
export const HoverSelectToken = styled.div`
  p {
    color: #656565;
    font-family: "Rethink Sans";
    font-size: 15px;
    line-height: 125%;
  }
`;

export const PopoverContent = styled.div`
  .swap-summary {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    p {
      border-radius: 19px;
      background: #d6d6d6;
      padding: 2px 8px;
      font-size: 11px;
      font-weight: 500;
    }
  }
  .swap-path {
    border-top: solid 1px #eee;
    padding-top: 10px;
    margin-top: 5px;
  }
  .swap-path-inner {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .swap-step {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .swap-step-router {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
    .text-router {
      color: #000;
      font-family: "Rethink Sans";
      font-size: 10px;
      font-weight: 600;
      line-height: 1;
    }
  }
  .swap-step-to {
    text-align: center;
    line-height: 1;
  }
  .swap-step-token {
    position: relative;
    line-height: 1;
    width: 21px;
    margin: 0 auto;
    .icon-chain {
      position: absolute;
      bottom: 0;
      right: -2px;
    }
  }
  .arrow {
    line-height: 1;
  }
  .price {
    color: #000;
    font-family: "Rethink Sans";
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    display: block;
  }
`;

//noti slippage
export const BlockContentSlippage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: solid 1px #38383a;
  font-family: "Rethink Sans";
  .slippage-left {
    display: flex;
    gap: 6px;
  }
  flex-shrink: 0;
  .button-change {
    background: var(--Blue--Pri-200, #b5ebff);
    padding: 6px 8px;
    color: var(--Blue--Pri-700, #007af5);
    outline: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: 0;
    border-radius: 20px;
    cursor: pointer;
  }
`;
