import React, { useContext, useState } from "react";
import { BlockButton, ModalStatusContainer, ModalWrapper } from "./styled";
import { RouterMain } from "../../../Bridgev2/Confirm/Modal/styled";
import { formatNumber } from "../../../../utils/convertNumber";
import { ContextProviderWrapper } from "../../../../components/Context";
import default_token_image from "../../../../assets/Dashboard/Common/default-token-image.png";
import { ButtonCommon } from "../../../../Layout/styled";

interface ModalStatusInterface {
  dataConfirm: any;
  formatToTwoDecimals: any;
}

const ModalStatus = ({
  dataConfirm,
  formatToTwoDecimals,
}: ModalStatusInterface) => {
  const { isMobile, theme } = useContext(ContextProviderWrapper)!;
  // const [isStatus, setIsStatus] = useState("pending");
  // const [isStatus, setIsStatus] = useState("success");
  const [isStatus, setIsStatus] = useState("fail");
  return (
    <ModalStatusContainer
      className={`${theme} 
        ${
          isStatus === "pending"
            ? "is-pending"
            : isStatus === "success"
            ? "is-success"
            : "is-fail"
        }
      `}
    >
      <ModalWrapper className={theme}>
        <div className="logo-status">
          <img
            src={
              isStatus === "pending"
                ? `/assets/images/bridge/icn_pending${
                    theme === "light" ? "_02" : ""
                  }.png`
                : isStatus === "success"
                ? `/assets/images/bridge/icn_success${
                    theme === "light" ? "_02" : ""
                  }.png`
                : `/assets/images/bridge/icn_fail${
                    theme === "light" ? "_02" : ""
                  }.png`
            }
            width={60}
            height={60}
            loading="lazy"
            alt="Ion Status"
            onError={(e) => (e.currentTarget.src = default_token_image)}
          />
        </div>
        <h3 className="title-status">
          {isStatus === "pending"
            ? "Pending Transaction"
            : isStatus === "success"
            ? "Successfully Swap"
            : "Swap Failed"}
        </h3>
        <RouterMain className={theme}>
          <div className="block-from">
            <div className="block-logo">
              <img
                src={dataConfirm?.swaps?.[0]?.from?.logo}
                alt="Logo Token"
                loading="lazy"
                className="logo-main"
                width={36}
                height={36}
                onError={(e) => (e.currentTarget.src = default_token_image)}
              />
              <img
                src={dataConfirm?.swaps?.[0]?.from?.blockchainLogo}
                alt="Logo Chain"
                loading="lazy"
                className="chain-logo"
                width={18}
                height={18}
                onError={(e) => (e.currentTarget.src = default_token_image)}
              />
            </div>
            <div className="block-price">
              <span className="token">
                {formatToTwoDecimals(
                  Number(
                    formatNumber(
                      dataConfirm?.swaps?.[0]?.fromAmount,
                      dataConfirm?.swaps?.[0]?.from?.decimals
                    )
                  )
                )}
              </span>
              <span className="price">
                $
                {formatToTwoDecimals(
                  Number(
                    formatNumber(
                      dataConfirm?.swaps?.[0]?.fromAmount,
                      dataConfirm?.swaps?.[0]?.from?.decimals
                    )
                  ) * dataConfirm?.swaps?.[0]?.from?.usdPrice
                )}
              </span>
            </div>
          </div>
          <div className="line">
            <img
              src={`${
                !isMobile
                  ? "/assets/images/bridge/icn_line_157.svg"
                  : "/assets/images/bridge/icn_line_08.svg"
              }`}
              width={300}
              height={6}
              loading="lazy"
              alt="Line"
              className="road-line"
              onError={(e) => (e.currentTarget.src = default_token_image)}
            />
          </div>
          <div className="block-from">
            <div className="block-logo">
              <img
                src={
                  dataConfirm?.swaps?.length > 1
                    ? dataConfirm?.swaps?.at(-1)?.to?.logo
                    : dataConfirm?.swaps?.[0]?.to?.logo
                }
                alt="Logo Token"
                loading="lazy"
                className="logo-main"
                width={36}
                height={36}
                onError={(e) => (e.currentTarget.src = default_token_image)}
              />
              <img
                src={
                  dataConfirm?.swaps?.length > 1
                    ? dataConfirm?.swaps?.at(-1)?.to?.blockchainLogo
                    : dataConfirm?.swaps?.[0]?.to?.blockchainLogo
                }
                alt="Logo Chain"
                loading="lazy"
                className="chain-logo"
                width={18}
                height={18}
                onError={(e) => (e.currentTarget.src = default_token_image)}
              />
            </div>
            <div className="block-price">
              <span className="token">
                {formatToTwoDecimals(
                  Number(
                    dataConfirm?.swaps?.length > 1
                      ? dataConfirm?.swaps?.at(1)?.toAmount
                      : dataConfirm?.swaps?.[0]?.toAmount
                  )
                )}
              </span>
              <span className="price">
                $
                {formatToTwoDecimals(
                  dataConfirm?.swaps?.at(1)?.toAmount *
                    dataConfirm?.swaps?.at(1)?.to?.usdPrice
                )}
              </span>
            </div>
          </div>
        </RouterMain>
        <p className={`${theme} infor-status`}>
          Swapping 0.1 BNB from BSC to 97.74 USDT on Avax
        </p>
      </ModalWrapper>
      <BlockButton>
        {isStatus !== "pending" && (
          <ButtonCommon
            color={theme === "light" ? "#141518" : ""}
            background={theme === "light" ? "#EEEEF0" : "#43424A"}
          >
            <p>See Details</p>
          </ButtonCommon>
        )}
        <ButtonCommon
          className={isStatus === "pending" ? "is-full" : ""}
          background={isStatus === "fail" ? "#D83B3B" : ""}
        >
          <p>
            {isStatus === "pending"
              ? "Pending..."
              : isStatus === "success"
              ? "Done"
              : "Try Again"}
          </p>
        </ButtonCommon>
      </BlockButton>
    </ModalStatusContainer>
  );
};

export default ModalStatus;
