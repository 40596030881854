import { useContext } from "react";
import { ContextProviderWrapper } from "../../Context";
import balance_light from "../../../assets/Dashboard/Common/balance_icon_light.svg";
import balance from "../../../assets/Dashboard/Common/balance_icon.svg";
import { ActionConnect } from "../Dashboard/styled";
import ModalOverlay from "../../Common/ModalOverlay";
import ModalConnectMultiChain from "./Modal";
import { useSelector } from "react-redux";

const WalletMultichain = () => {
  const { theme, openModalWallet, setOpenModalWallet } = useContext(
    ContextProviderWrapper
  )!;
  const connectedWallets = useSelector(
    (state: any) => state.accountSlice.referrals
  );

  return (
    <>
      <ModalOverlay
        component={<ModalConnectMultiChain />}
        open={openModalWallet}
        setOpen={setOpenModalWallet}
        title="CONNECT WALLET"
        width="530px"
      />
      <ActionConnect
        className={theme}
        onClick={() => {
          setOpenModalWallet(true);
        }}
      >
        {connectedWallets?.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {connectedWallets?.map((item: any, index: any) => {
              return (
                <figure key={index}>
                  <img src={item.img} alt="icon" loading="lazy" />
                </figure>
              );
            })}
            <p>
              {connectedWallets?.length > 0 ? (
                `${connectedWallets?.length} connected`
              ) : (
                <></>
              )}
            </p>
          </div>
        ) : (
          <>
            <figure>
              <img
                src={theme === "light" ? balance_light : balance}
                alt="icon"
              />
            </figure>
            <p>Connect Wallet</p>
          </>
        )}
      </ActionConnect>
    </>
  );
};

export default WalletMultichain;
