import { Spin } from "antd";
import { useSelector } from "react-redux";
import {
  CautionContainer,
  CautionFee,
  CautionFeeItem,
  CautionLoading,
} from "./styled";
import { useEffect } from "react";

const CautionBlock = ({
  confirmRouter,
  loadingConfirmRouter,
  valueInputFrom,
  caution,
  setCaution,
}: any) => {
  const nativeToken = useSelector((state: any) => state.token.nativeTokens);
  const checkWalletsForFee = useSelector(
    (state: any) => state.accountSlice.checkUserWalletForFee
  );

  const extractFeesFromSourceWallet = (swaps: any) => {
    const aggregatedFees: Record<string, any> = {};

    swaps?.forEach((swap: any) => {
      const chain = swap?.from.blockchain;
      const swapperId = swap?.swapperId;
      const fees = swap.fee
        ?.filter((fee: any) => fee.expenseType === "FROM_SOURCE_WALLET")
        ?.map((fee: any) => ({
          amount: Number(fee.amount),
          price: fee.price,
          totalFeeUsd: Number(fee.amount) * fee.price,
        }));

      if (!fees?.length) return;

      const totalAmount = fees.reduce(
        (sum: number, fee: any) => sum + fee.amount,
        0
      );
      const totalFeesUsd = fees.reduce(
        (sum: number, fee: any) => sum + fee.totalFeeUsd,
        0
      );

      if (aggregatedFees[chain]) {
        aggregatedFees[chain].totalAmount += totalAmount;
        aggregatedFees[chain].totalFeesUsd += totalFeesUsd;
      } else {
        aggregatedFees[chain] = {
          swapperId,
          chain,
          totalAmount,
          totalFeesUsd,
        };
      }
    });
    return Object.values(aggregatedFees).map((fee) => ({
      ...fee,
      totalAmount: fee.totalAmount.toFixed(8),
      totalFeesUsd: fee.totalFeesUsd.toFixed(2),
    }));
  };

  const filterNativeTokens = (feeData: any[]) => {
    return feeData.map((fee: any) => {
      const native = nativeToken.find(
        (token: any) => token.blockchain === fee.chain
      );
      return {
        ...fee,
        isNativeToken: !!native,
        nativeToken: native || null,
      };
    });
  };

  const checkWalletBalanceBasedChain = (chain: any) => {
    const result = checkWalletsForFee
      .filter((fee: any) => fee.blockchain === chain)
      .map((i: any) => {
        return i.balanceNative;
      });
    return result;
  };

  useEffect(() => {
    const feeData = extractFeesFromSourceWallet(confirmRouter);
    const feeDataWithNativeTokens = filterNativeTokens(feeData);

    const updatedCautions: JSX.Element[] = [];

    if (
      checkWalletsForFee &&
      valueInputFrom > checkWalletsForFee[0]?.balanceToken
    ) {
      updatedCautions.push(
        <>
          Needs{" "}
          <span>
            {valueInputFrom} {checkWalletsForFee[0]?.token}
          </span>{" "}
          for swap, but you have{" "}
          <span>{checkWalletsForFee[0]?.balanceToken}</span>{" "}
          <span>{checkWalletsForFee[0]?.token}</span> in your{" "}
          <span>{checkWalletsForFee[0]?.blockchain}</span> wallet
        </>
      );
    }

    feeDataWithNativeTokens.forEach((item) => {
      const walletBalance = checkWalletBalanceBasedChain(item.chain);
      if (walletBalance < item.totalAmount) {
        updatedCautions.push(
          <>
            Needs <span>{item.totalAmount}</span>{" "}
            <span>{item.nativeToken?.symbol}</span> for network fee, but you
            have <span>{walletBalance}</span>{" "}
            <span>{item.nativeToken?.symbol}</span> in your{" "}
            <span>{item.chain}</span> wallet
          </>
        );
      }
    });

    setCaution(updatedCautions);
  }, [confirmRouter, valueInputFrom]);

  return (
    <CautionContainer>
      {loadingConfirmRouter ? (
        <CautionLoading>
          <Spin size="large" />
        </CautionLoading>
      ) : (
        caution.length > 0 && (
          <CautionFee>
            <p>Caution:</p>
            <div>
              {caution.map((item: any, index: any) => (
                <CautionFeeItem key={index}>+ {item}</CautionFeeItem>
              ))}
            </div>
          </CautionFee>
        )
      )}
    </CautionContainer>
  );
};

export default CautionBlock;
