import { createSlice } from "@reduxjs/toolkit";
import { Address } from "ton3-core";

export interface AccountState {
  affiliate?: Address;
  referrals: Address[];
  accountDetail?: any;
  dataChain?: any;
  checkUserWalletForFee?: any;
}

const initialState: AccountState = {
  referrals: [],
  accountDetail: null,
  dataChain: [],
  checkUserWalletForFee: null,
};

export const AccountSlice = createSlice({
  name: "accountSlice",
  initialState,
  reducers: {
    addConnectedWallet(state, action) {
      const wallet = action.payload;
      if (
        !state.referrals.some(
          (referral: any) => referral.address === wallet.address
        )
      ) {
        state.referrals.push(wallet);
      }
    },
    // addConnectedWallet: (state, action) => {
    //   const walletIndex = state.referrals.findIndex(
    //     (wallet: any) => wallet.id === action.payload.id
    //   );
    //   if (walletIndex !== -1) {
    //     state.referrals[walletIndex] = action.payload;
    //   } else {
    //     state.referrals.push(action.payload);
    //   }
    // },
    removeConnectedWallet(state, action) {
      const addressToRemove = action.payload;
      state.referrals = state.referrals.filter(
        (referral: any) => referral.address !== addressToRemove
      );
    },
    dataChainStore(state, action) {
      state.dataChain = action.payload;
    },
    updateMarkedResult(state, action) {
      state.checkUserWalletForFee = action.payload;
    },
  },
});

export const {
  addConnectedWallet,
  removeConnectedWallet,
  dataChainStore,
  updateMarkedResult,
} = AccountSlice.actions;
export const selectAffiliate = (state: AccountState) => state.affiliate;
export const selectReferrals = (state: AccountState) => state.referrals;
