import { Controller, useForm } from "react-hook-form";
import {
  ChooseTokenSearch,
  ChooseTokenWrapper,
} from "../../../../Swap/ChooseToken/styled";
import InputCommon from "../../../../../components/Common/Input";
import search_icon from "../../../../../assets/Dashboard/Common/search_icon.svg";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../../../../components/Context";
import clear from "../../../../../assets/Dashboard/Common/clear.svg";
import external_icon from "../../../../../assets/Dashboard/Common/external_link.svg";
import delete_icon from "../../../../../assets/Dashboard/Common/delete_icon.svg";
import {
  AddTokenContainer,
  ListNewTokenContainer,
  NewTokenChainLogo,
  NewTokenContent,
  NewTokenDelete,
  NewTokenItem,
  NewTokenLogo,
} from "./styled";
import CustomChooseChain from "./Chain";
import { KIBBLE_API } from "../../../../../services/api";
import { ButtonCommon } from "../../../../../Layout/styled";
import toast from "react-hot-toast";
import { Spin } from "antd";
import { shortenAddress } from "../../../../../utils/addressUser";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

type FormValuesProps = {
  token: string;
};

const CustomAddToken = () => {
  const [chainSelected, setChainSelected] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const dataChainStore = useSelector(
    (state: any) => state.accountSlice.dataChain
  );
  const [listNewToken, setListNewToken] = useState<any>(() => {
    const storedTokens = localStorage.getItem("listNewToken");
    return storedTokens ? JSON.parse(storedTokens) : [];
  });
  const { theme } = useContext(ContextProviderWrapper)!;
  const defaultValues = {
    token: "",
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: "onChange",
  });
  const { control, watch, setValue } = methods;
  const watchAddressToken = watch("token");

  const handleGetChains = async () => {
    setIsLoading(true);
    const params = {
      blockchain: chainSelected,
      contract: watchAddressToken,
    };

    const isTokenExists = listNewToken.some(
      (token: any) =>
        token.address?.toLowerCase().trim() ===
        watchAddressToken?.toLowerCase().trim()
    );

    if (isTokenExists) {
      toast.error("Token already added!", { id: "errorTokenExists" });
      setIsLoading(false);
      return;
    }

    try {
      const res = await KIBBLE_API.addCustomToken(params);
      if (res.status === 200 && !res.data.error) {
        const newTokenList = [...listNewToken, res.data.token];

        const updatedListNewToken = newTokenList.map((token: any) => {
          const tokenBlockchain = token.blockchain?.toUpperCase();
          const chainInfo = dataChainStore.find(
            (chain: any) => chain.name?.toUpperCase() === tokenBlockchain
          );
          return {
            ...token,
            chainInfo,
          };
        });

        setListNewToken(updatedListNewToken);
        localStorage.setItem(
          "listNewToken",
          JSON.stringify(updatedListNewToken)
        );

        toast.success("Added token successfully");
        setValue("token", "");
      }
      if (res.data.error) {
        toast.error(res.data.error, { id: "errorAddToken" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteToken = (indexToDelete: number) => {
    const updatedList = listNewToken.filter(
      (_: any, index: number) => index !== indexToDelete
    );
    setListNewToken(updatedList);
    localStorage.setItem("listNewToken", JSON.stringify(updatedList));
  };

  useEffect(() => {
    localStorage.setItem("listNewToken", JSON.stringify(listNewToken));
  }, [listNewToken]);

  return (
    <AddTokenContainer>
      <ChooseTokenWrapper>
        <CustomChooseChain
          chainSelected={chainSelected}
          setChainSelected={setChainSelected}
        />
        <ChooseTokenSearch className={theme}>
          <Controller
            name="token"
            control={control}
            render={({ field }: any) => (
              <InputCommon
                {...field}
                placeHolder="Enter token address"
                name="token"
                allowClear={{ clearIcon: <img src={clear} alt="icon" /> }}
                prefix={<img src={search_icon} alt="icon" />}
              />
            )}
          />
          <p>
            <span>New Token</span>
          </p>
        </ChooseTokenSearch>
      </ChooseTokenWrapper>
      <ListNewTokenContainer>
        {listNewToken && listNewToken?.length > 0 ? (
          <>
            {listNewToken.map((item: any, index: any) => {
              return (
                <>
                  <NewTokenItem key={index}>
                    <NewTokenLogo>
                      <img width={30} height={30} src={item.image} alt="icon" />
                      <NewTokenChainLogo>
                        <img
                          width={18}
                          height={18}
                          src={item.blockchainImage}
                          alt="icon"
                        />
                      </NewTokenChainLogo>
                    </NewTokenLogo>
                    <NewTokenContent>
                      <p>{item.symbol}</p>
                      <div>
                        <span>{item.name}</span>
                        <span>
                          <Link
                            to={item.chainInfo.info.addressUrl.replace(
                              "{wallet}",
                              item.address
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {shortenAddress(item.address)}
                            <figure>
                              <img src={external_icon} alt="icon" />
                            </figure>
                          </Link>
                        </span>
                      </div>
                    </NewTokenContent>
                    <NewTokenDelete onClick={() => handleDeleteToken(index)}>
                      <figure>
                        <img
                          width={10}
                          height={10}
                          src={delete_icon}
                          alt="icon"
                        />
                      </figure>
                    </NewTokenDelete>
                  </NewTokenItem>
                </>
              );
            })}
          </>
        ) : (
          <p
            style={{
              color: "#fff",
            }}
          >
            There isn't token added
          </p>
        )}
      </ListNewTokenContainer>
      {chainSelected?.length > 0 && watchAddressToken?.length > 0 && (
        <ButtonCommon
          onClick={() => {
            handleGetChains();
          }}
        >
          {isLoading ? (
            <Spin
              style={{
                background: "#fff",
              }}
            />
          ) : (
            "Adding token"
          )}
        </ButtonCommon>
      )}
    </AddTokenContainer>
  );
};

export default CustomAddToken;
