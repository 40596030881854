import styled from "styled-components";
import { InputContainer } from "../../../components/Common/Input/styled";
import { SearchBox } from "../../Liquidity/styled";

export const SettingsContainer = styled.div`
  color: #fff;
  font-family: "Rethink Sans";
`;
export const SettingBridge = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  .bridge-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .total-bridge {
    position: relative;
    padding-right: 20px;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      background: url("/assets/images/bridge/icn_down_small_grey.svg") center/
        cover no-repeat;
      width: 20px;
      height: 20px;
    }
  }
  .block-bridge {
    height: 250px;
    overflow: auto;
    padding: 20px 0;
  }
  .item-bridge {
    .ant-checkbox-wrapper {
      width: 100%;
      padding: 10px 0;
      justify-content: space-between;
      border-bottom: solid 1px #4d4d57;
    }
    .ant-checkbox {
      order: 2;
    }
  }
  .content-bridge {
    overflow: hidden;
    transition: all 0.26s ease;
  }
  .item-left {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .text-bridge {
    color: #fff;
  }
  ${InputContainer} {
    margin-bottom: 0;
  }
  ${SearchBox} {
    margin-bottom: 4px;
  }
  .ant-input {
    height: 32px;
  }
`;
