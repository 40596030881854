import styled from "styled-components";
import { InputContainer } from "../../../../../components/Common/Input/styled";
import { ChooseTokenSearch } from "../../../../Swap/ChooseToken/styled";

export const AddTokenContainer = styled.div`
  ${InputContainer} {
    background-color: #1c1c1e;
  }
  ${ChooseTokenSearch} {
    margin-bottom: 10px;
  }
`;
export const ListNewTokenContainer = styled.div`
  display: flex;
  gap: 15px 5px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  max-height: 200px;
  overflow-y: auto;
  justify-content: space-between;
  & > div {
    display: flex;
    width: calc(50% - 5px);
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
`;
export const NewTokenItem = styled.div``;
export const NewTokenLogo = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  img {
    border-radius: 50%;
    overflow: hidden;
  }
`;
export const NewTokenChainLogo = styled.figure`
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;
export const NewTokenContent = styled.div`
  width: 100%;
  p {
    color: #fff;
  }
  span {
    color: #807f7f;
    font-size: 14px;
  }
  &:hover {
    & > div {
      span {
        &:nth-child(1) {
          transform: translateY(-20px);
        }
        &:nth-child(2) {
          transform: translateY(-15px);
        }
      }
    }
  }
  & > div {
    position: relative;
    overflow: hidden;
    height: 15px;
    span {
      position: absolute;
      top: 0;
      display: block;
      transition: all 0.2s linear;
      &:nth-child(2) {
        top: 15px;
        a {
          color: #807f7f;
          display: flex;
          align-items: center;
          gap: 5px;
          figure {
            position: relative;
            top: -6px;
            width: 10px;
            height: 10px;
            opacity: 0.5;
          }
        }
      }
    }
  }
`;
export const NewTokenDelete = styled.div`
  cursor: pointer;
  transition: all 0.15s linear;
  margin-left: auto;
  figure {
    width: 13px;
    height: 13px;
  }
  &:hover {
    opacity: 0.7;
  }
`;
