import { instance, instance2 } from "../instance";

export const KIBBLE_API = {
  getInfoToken(contract: any) {
    return instance.get(`/token/get_token_info?address=${contract}`);
  },
  simulateCreatePool(params: any) {
    return instance.post(`/dex/pools/simulate_create_pool`, params);
  },
  confirmSimulateCreatePool(params: any) {
    return instance.post(`/dex/liquidity/provide`, params);
  },
  getAllPools(params: any) {
    return instance.post(
      `/dex/pools/balances?load_lp_accounts=${params.load_lp_accounts}`
    );
  },
  getAllAssets(params: any) {
    return instance.get(`/assets?is_conmunity=${params}`);
  },
  getPoolDetail(params: any, address: any) {
    if (address) {
      return instance.get(
        `/pools/detail?pool_address=${params}&wallet_address=${address}`
      );
    } else {
      return instance.get(`/pools/detail?pool_address=${params}`);
    }

    // return instance.get(`/pools/detail?pool_address=${params}`);
  },
  addNewAssetToken(params: any) {
    return instance.post(`/assets/create`, params);
  },
  checkTokenInPools(params: any) {
    return instance.get(
      `/pools/check?token0_address=${params.token0_address}&token1_address=${params.token1_address}`
    );
  },
  searchToken(params: any) {
    return instance.post(`/token/search`, params);
  },
  checkStatusSwap(params: any) {
    return instance.get(
      `/transactions/status?wallet_address=${params.wallet_address}&query_id=${params.query_id}`
    );
  },
  submitFavoriteToken(params: any) {
    return instance.post(`/assets/favorite`, params);
  },
  //multichain
  getBalanceMultichain(params: any) {
    return instance2.get(`/multichain/balance${params.chain}`);
  },
  getBlockChain(apiKey: any) {
    return instance2.get(`/metadata/blockchains?apiKey=${apiKey}`);
  },
  getToken(apiKey: any) {
    return instance2.get(`/metadata/metadata?apiKey=${apiKey}`);
  },
  getTokenBalance({
    evmAddress,
    blockchain,
    symbol,
    address,
    apiKey,
  }: {
    evmAddress: any;
    blockchain: any;
    symbol: any;
    address?: any;
    apiKey: any;
  }) {
    const endpoint = `/multichain/token-balance?walletAddress=${evmAddress}&blockchain=${blockchain}&symbol=${symbol}`;
    const addressParam = address ? `&contract=${address}` : "";
    const apiKeyParam = `&apiKey=${apiKey}`;
    const finalUrl = `${endpoint}${addressParam}${apiKeyParam}`;

    return instance2.get(finalUrl);
  },
  getRouting(params: any) {
    return instance2.get(`/multichain/routing/bests`, { params });
  },
  getMultichainBalance(chain: string, address: string, apiKey: string) {
    return instance2.get(
      `/multichain/balance?address=${chain}.${address}&apiKey=${apiKey}`
    );
  },
  addCustomToken(params: any) {
    return instance2.get(
      `/metadata/custom-token?blockchain=${params.blockchain}&contract=${params.contract}`
    );
  },
  confirmRouting(params: any) {
    const url = `/multichain/routing/route-confirm?requestId=${params.requestId}&selectedWallets=${params.selectedWallets}`;
    const finalUrl = params.destination
      ? `${url}&destination=${params.destination}`
      : url;
    return instance2.post(finalUrl);
  },
  checkApproval(params: any) {
    return instance2.get(
      `multichain/tx/check-approval?requestId=${params.requestId}&txId=${params.txId}`
    );
  },
  checkStatus(params: any) {
    return instance2.get(
      `multichain/tx/check-status?requestId=${params.requestId}&txId=${params.txId}&step=${params.step}`
    );
  },
  createTx(params: any) {
    return instance2.post(`multichain/tx/createTX`, params)
  }
};
