import styled from "styled-components";

export const ChooseChainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 30px;
  max-height: 200px;
  overflow-y: auto;
  & > div {
    display: flex;
    align-items: center;
    width: calc(50% - 25px);
    gap: 10px;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.15s linear;
    figure {
      width: 30px;
      height: 30px;
    }
    p {
      color: #fff;
    }
    &:hover,
    &.active {
      background-color: #3d3d3d;
    }
  }
`;
