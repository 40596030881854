import { SwapWrapper } from "../../Swap/styled";
import {
  BlockRouter,
  BlockRouterInner,
  HeadingRouter,
  NoFoundRouter,
  PopoverContent,
} from "../../Bridge/styled";
import { useContext, useEffect } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { formatNumber } from "../../../utils/convertNumber";
import LoadingSpin from "../../../components/Common/Loading";
import { Popover } from "antd";
import "./popover.css";

interface RouterInterface {
  pathRouter: any;
  setIsShowRouterMobile: any;
  isLoadingRouter: any;
  setClickRouter: any;
  clickRouter: any;
  setSelectedToData: any;
  setRouterDetail: any;
  setDataConfirm: any;
  setSlippageRouter: any;
}
const RouterV2 = ({
  pathRouter,
  setIsShowRouterMobile,
  isLoadingRouter,
  setClickRouter,
  clickRouter,
  setSelectedToData,
  setRouterDetail,
  setDataConfirm,
  setSlippageRouter,
}: RouterInterface) => {
  const { isDesktop, isMobile, theme } = useContext(ContextProviderWrapper)!;

  const formatToTwoDecimals = (number: number) => {
    return Math.floor(number * 100) / 100;
  };

  // get dataConfirm is first item when has pathRouter
  useEffect(() => {
    if (pathRouter?.results?.length > 0) {
      setDataConfirm(pathRouter.results[0]);
      setClickRouter({
        id: 0,
        requestId: pathRouter.results[0].requestId,
      });
      setSlippageRouter(pathRouter?.results[0]?.swaps[0]);
    }
  }, [pathRouter]);

  const calculateRouterDetails = (swaps: any[]) => {
    const totalTime = swaps.reduce(
      (total: number, item: any) => total + (item?.estimatedTimeInSeconds || 0),
      0
    );

    const totalNetworkFee = swaps.reduce((total: number, swap: any) => {
      // Sum all fees except Rango Fee
      const networkFee = swap?.fee
        ?.filter((fee: any) => fee.name !== "Rango Fee") //except Rango Fee
        .reduce(
          (feeTotal: number, fee: any) => feeTotal + fee.amount * fee.price,
          0
        );
      return total + networkFee;
    }, 0);

    const intermediaryLogos = swaps.flatMap((swap: any) => {
      if (swap?.internalSwaps?.length > 0) {
        return swap?.internalSwaps?.map(
          (internalSwap: any) => internalSwap.swapperLogo
        );
      } else {
        return swap?.swapperLogo ? [swap?.swapperLogo] : [];
      }
    });

    const uniqueLogos = Array.from(new Set(intermediaryLogos));
    return { totalTime, totalNetworkFee, intermediaryLogos: uniqueLogos };
  };

  useEffect(() => {
    if (pathRouter?.results?.length > 0) {
      const firstItem = pathRouter.results[0];
      const lastSwap = firstItem?.swaps?.[firstItem.swaps.length - 1];
      if (lastSwap) {
        setSelectedToData({
          amount: Number(lastSwap.toAmount),
          usdPrice: lastSwap.to.usdPrice,
        });
      }
      setClickRouter({
        id: 0,
        requestId: firstItem.requestId,
      });

      const { totalTime, totalNetworkFee } = calculateRouterDetails(
        firstItem?.swaps
      );
      const intermediaryLogos = firstItem?.swaps?.flatMap(
        (swap: any) =>
          swap?.internalSwaps?.map(
            (internalSwap: any) => internalSwap.swapperLogo
          ) || []
      );
      setRouterDetail({ totalTime, totalNetworkFee, intermediaryLogos });
    }
  }, [pathRouter]);

  // Function to display Popover
  const renderInternalSwapsPath = (item: any) => {
    const pathElements: JSX.Element[] = [];

    // Calculate total fee and time
    const totalFee = item?.fee
      ?.filter((fee: any) => fee.name !== "Rango Fee")
      .reduce((acc: number, fee: any) => acc + fee.amount * fee.price, 0);

    const totalTime = item?.estimatedTimeInSeconds || 0;

    // If there is no internalSwaps, only show Fee and Time
    if (!item?.internalSwaps || item?.internalSwaps.length === 0) {
      return (
        <div className="swap-summary">
          <div>
            <p>Cost: ${formatToTwoDecimals(totalFee)}</p>
          </div>
          <div>
            <p>Time: {totalTime} sec</p>
          </div>
        </div>
      );
    }
    // Add 'from' logo and symbol
    if (item?.from) {
      pathElements.push(
        <div key="from" className="swap-step-to">
          <div className="swap-step-token">
            <img
              src={item?.from?.logo}
              alt={item?.from?.symbol}
              width={20}
              height={20}
              loading="lazy"
            />
            <img
              src={item?.from?.blockchainLogo}
              alt={item?.from?.symbol}
              width={10}
              height={10}
              className="icon-chain"
              loading="lazy"
            />
          </div>
          <span className="price">
            {formatToTwoDecimals(
              Number(formatNumber(item?.fromAmount, item?.from?.decimals))
            )}
          </span>
        </div>
      );
    }

    // Add internalSwaps if any
    if (item?.internalSwaps?.length > 0) {
      item.internalSwaps.forEach((internal: any, idx: number) => {
        pathElements.push(
          <div key={`internal-${idx}`} className="swap-step">
            <div className="swap-step-router">
              <img
                src={internal?.swapperLogo}
                alt={internal?.swapperId}
                width={20}
                height={20}
                loading="lazy"
              />
              <span className="text-router">{internal?.swapperId}</span>
            </div>
            <span className="arrow">
              <img
                src="/assets/images/bridge/icn_arrow_right_grey.svg"
                width={20}
                height={20}
                loading="lazy"
                alt="Arrow"
              />
            </span>
            <div className="swap-step-to">
              <div className="swap-step-token">
                <img
                  src={internal?.to?.logo}
                  alt={internal?.to?.symbol}
                  width={20}
                  height={20}
                  loading="lazy"
                />
                <img
                  src={internal?.to?.blockchainLogo}
                  alt={internal?.to?.blockchain}
                  width={10}
                  height={10}
                  className="icon-chain"
                  loading="lazy"
                />
              </div>
              <span className="price">
                {formatToTwoDecimals(
                  Number(
                    formatNumber(internal?.toAmount, internal?.to?.decimals)
                  )
                )}
              </span>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="swap-path">
        <div className="swap-summary">
          <div>
            <div>
              <p>Cost: ${formatToTwoDecimals(totalFee)}</p>
            </div>
          </div>
          <div>
            <p>Time: {totalTime} sec</p>
          </div>
        </div>
        <div className="swap-path-inner">
          {pathElements.map((el, idx) => (
            <div key={idx} className="swap-path-inner">
              {el}
              {idx < pathElements.length - 1 && (
                <span className="arrow">
                  <img
                    src="/assets/images/bridge/icn_arrow_right_grey.svg"
                    width={20}
                    height={20}
                    loading="lazy"
                    alt="Arrow"
                  />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  /* Function to render Popover content */
  const renderSwapPopover = (item: any) => {
    return (
      <PopoverContent className="popover-main">
        <div className="popover-section">
          <strong>Details Bridge</strong>
          {renderInternalSwapsPath(item)}
        </div>
      </PopoverContent>
    );
  };

  return (
    <SwapWrapper
      className={`
        ${theme} ${
        pathRouter?.results?.length <= 0
          ? "no-router"
          : isLoadingRouter
          ? "is-loading"
          : ""
      }`}
    >
      {isLoadingRouter ? (
        <LoadingSpin />
      ) : pathRouter?.results?.length > 0 ? (
        <>
          <HeadingRouter className={theme}>
            {isDesktop ? (
              <>
                <h3 className="title-router">Best Route Recommend</h3>
                <span className="time-router">
                  <img
                    src="/assets/images/bridge/icn_reload.svg"
                    width={13}
                    height={13}
                    alt="Reload"
                    loading="lazy"
                  />
                  20sec
                </span>
              </>
            ) : (
              <h3
                className="title-router is-flex"
                onClick={() => setIsShowRouterMobile(false)}
              >
                <img
                  src={`${
                    theme === "light"
                      ? "/assets/images/bridge/icn_arrow_left_black.svg"
                      : "/assets/images/bridge/icn_arrow_left_white.svg"
                  }`}
                  alt="Back to Bridge"
                  loading="lazy"
                />
                Back to Bridge
              </h3>
            )}
          </HeadingRouter>
          {pathRouter?.results?.map((items: any, index: number) => (
            <>
              <BlockRouter
                className={`${theme}
                  ${
                    clickRouter.requestId === items?.requestId
                      ? "is-border"
                      : ""
                  }
                `}
                onClick={() => {
                  setSlippageRouter(items?.swaps[0]);
                  const { totalTime, totalNetworkFee, intermediaryLogos } =
                    calculateRouterDetails(items?.swaps);
                  setRouterDetail({
                    totalTime,
                    totalNetworkFee,
                    intermediaryLogos,
                  });
                  const lastSwap = items?.swaps?.[items.swaps.length - 1];
                  setDataConfirm(items);
                  if (lastSwap) {
                    setSelectedToData({
                      amount: Number(lastSwap.toAmount),
                      usdPrice: lastSwap.to.usdPrice,
                    });
                  }
                  setClickRouter({
                    id: index,
                    requestId: items.requestId,
                  });
                  if (isMobile) {
                    setIsShowRouterMobile(false);
                  }
                }}
              >
                <div className="block-top">
                  {(() => {
                    const { totalTime, totalNetworkFee } =
                      calculateRouterDetails(items?.swaps);
                    return (
                      <>
                        <ul className="list-function">
                          <li>Cost: ${formatToTwoDecimals(totalNetworkFee)}</li>
                          <li>Time: {totalTime} sec</li>
                        </ul>
                        <ul className="list-commend">
                          {items?.tags?.length > 0 &&
                            items.tags.map((tag: any, tagIndex: number) => (
                              <li
                                key={tagIndex}
                                className={`tag-${tag.value.toLowerCase()}`}
                              >
                                {tag.label}
                              </li>
                            ))}
                        </ul>
                      </>
                    );
                  })()}
                </div>
                <BlockRouterInner>
                  {items?.swaps?.map((item: any, index: number) => (
                    <Popover
                      key={index}
                      trigger="hover"
                      placement="top"
                      content={renderSwapPopover(item)}
                    >
                      <div
                        key={index}
                        className={`block-bottom ${
                          items?.swaps?.length <= 1
                            ? "is-full"
                            : items?.swaps?.length <= 2
                            ? "is-half"
                            : "is-small"
                        } ${theme}`}
                      >
                        <div className="router-detail">
                          <div className="router-from">
                            <div className="router-logo">
                              <div className="router-left">
                                <img
                                  src={item?.from?.logo}
                                  width={33}
                                  height={33}
                                  loading="lazy"
                                  alt="Icon chain"
                                />
                                <img
                                  src={item?.from?.blockchainLogo}
                                  width={16}
                                  height={16}
                                  className="icon-small"
                                  loading="lazy"
                                  alt="Icon chain"
                                />
                              </div>
                              <div className="router-right">
                                <span className="token">
                                  {formatToTwoDecimals(
                                    Number(
                                      formatNumber(
                                        item?.fromAmount,
                                        item?.from?.decimals
                                      )
                                    )
                                  )}{" "}
                                  {/* {item?.from?.symbol} */}
                                </span>
                                <span className="price">
                                  $
                                  {formatToTwoDecimals(
                                    Number(
                                      formatNumber(
                                        item?.fromAmount,
                                        item?.from?.decimals
                                      )
                                    ) * item?.from?.usdPrice
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {item?.internalSwaps?.length > 0 ? (
                          <div
                            className={`router-intermediary ${
                              index === items?.swaps?.length - 1
                                ? "router-intermediary-last"
                                : ""
                            }`}
                          >
                            {!isMobile ? (
                              item?.internalSwaps?.map(
                                (itemRouter: any, index: number) => (
                                  <img
                                    key={index}
                                    src={itemRouter?.swapperLogo}
                                    width={14}
                                    height={14}
                                    className="icon-small icon-logo"
                                    loading="lazy"
                                    alt="Icon chain"
                                  />
                                )
                              )
                            ) : (
                              <>
                                <img
                                  src="/assets/images/bridge/icn_arrow_right_grey.svg"
                                  width={20}
                                  height={20}
                                  loading="lazy"
                                  alt="Arrow"
                                />
                              </>
                            )}
                          </div>
                        ) : (
                          <div
                            className={`router-intermediary ${
                              index === items?.swaps?.length - 1
                                ? "router-intermediary-last"
                                : ""
                            }`}
                          >
                            {!isMobile ? (
                              <img
                                key={index}
                                src={item?.swapperLogo}
                                width={14}
                                height={14}
                                className="icon-small icon-logo"
                                loading="lazy"
                                alt="Icon chain"
                              />
                            ) : (
                              <img
                                src="/assets/images/bridge/icn_arrow_right_grey.svg"
                                width={20}
                                height={20}
                                loading="lazy"
                                alt="Arrow"
                              />
                            )}
                          </div>
                        )}
                        <div
                          className={`router-detail ${
                            index === items?.swaps?.length - 1
                              ? ""
                              : "hidden-router-to"
                          }`}
                        >
                          <div className="router-to">
                            <div className="router-logo">
                              <div className="router-left">
                                <img
                                  src={item?.to?.logo}
                                  width={33}
                                  height={33}
                                  loading="lazy"
                                  alt="Icon chain"
                                />
                                <img
                                  src={item?.to?.blockchainLogo}
                                  width={16}
                                  height={16}
                                  className="icon-small"
                                  loading="lazy"
                                  alt="Icon chain"
                                />
                              </div>
                              <div className="router-right">
                                <span className="token">
                                  {formatToTwoDecimals(Number(item?.toAmount))}{" "}
                                  {/* {item?.to?.symbol} */}
                                </span>
                                <span className="price">
                                  $
                                  {formatToTwoDecimals(
                                    item?.toAmount * item?.to?.usdPrice
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {items?.swaps?.length > 1 && (
                      <div className="block-line-middle">
                        <img
                          src={
                            isDesktop
                              ? "/assets/images/bridge/icn_line.svg"
                              : "/assets/images/bridge/icn_arrow_right_grey.svg"
                          }
                          width={76}
                          height={6}
                          loading="lazy"
                          alt="Icon line"
                          className="icon-line"
                        />
                      </div>
                    )} */}
                    </Popover>
                  ))}
                </BlockRouterInner>
              </BlockRouter>
            </>
          ))}
        </>
      ) : (
        <NoFoundRouter>
          <div className="no-image">
            <img
              src="/assets/images/bridge/icn_no_router.png"
              width={204}
              height={109}
              loading="lazy"
              alt="No router"
            />
          </div>
          <p>No route found</p>
        </NoFoundRouter>
      )}
    </SwapWrapper>
  );
};

export default RouterV2;
