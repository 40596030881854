import { useTonConnectUI } from "@tonconnect/ui-react";
import { ButtonWallet, WalletConnectedTag } from "../styled";

const TonWalletList = ({ searchText }: any) => {
  const [tonConnectUI] = useTonConnectUI();

  const walletName = "Ton Connect";
  const isMatched =
    !searchText || walletName.toLowerCase().includes(searchText.toLowerCase());

  if (!isMatched) return null;

  return (
    <ButtonWallet
      onClick={() => {
        if (!tonConnectUI.connected) {
          tonConnectUI.openModal();
        } else {
          tonConnectUI.disconnect();
        }
      }}
    >
      <figure>
        <img
          src="https://raw.githubusercontent.com/rango-exchange/assets/7fb19ed5d5019b4d6a41ce91b39cde64f86af4c6/wallets/tonconnect/icon.svg"
          alt="icon"
        />
      </figure>
      <p>{walletName}</p>
      <WalletConnectedTag
        className={tonConnectUI.connected ? "connected" : "normal"}
      >
        {tonConnectUI.connected ? "Connected" : "Not Connect"}
      </WalletConnectedTag>
    </ButtonWallet>
  );
};

export default TonWalletList;
