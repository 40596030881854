import { Link } from "react-router-dom";
import styled from "styled-components";

export const WalletListStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  & > button {
    width: calc(25% - 4px);
    padding: 8px;
    border-radius: 4px;
    background: #282828;
    outline: none;
    font-family: "Rethink Sans", sans-serif;
    transition: all 0.15s linear;
    cursor: pointer;
    &:hover {
      background-color: rgba(75, 93, 108, 0.5);
    }
    p {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 767px) {
    & > button {
      width: calc(33.33% - 4px);
      p {
        font-size: 12px;
      }
    }
  }
`;
export const WalletConnectedTag = styled.span`
  font-size: 11px;
  font-weight: 500;
  &.connected {
    color: #09b260;
  }
  &.normal {
    color: #5e5e6b;
  }
  &.setup {
    color: #0a85e9;
  }
`;
export const ButtonWallet = styled.button`
  border: 1px solid transparent;
  &.active,
  &:hover {
    border-color: #536ea2;
  }
  figure {
    width: 35px;
    height: 35px;
    margin: 0 auto 5px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
