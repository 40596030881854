import styled from "styled-components";
import { RouterMain } from "../../../Bridgev2/Confirm/Modal/styled";
import { ButtonCommon } from "../../../../Layout/styled";

export const ModalStatusContainer = styled.div`
  color: #fff;
  min-height: 429px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &.is-success {
    ${RouterMain} {
      border: 1px solid var(--Spring-Green-800, #0a7140);
      background: var(--Spring-Green-950, #00341d);
    }
  }
  &.is-fail {
    ${RouterMain} {
      border: 1px solid var(--Red-700, #b32222);
      background: var(--Red-950, #430c0c);
    }
    .infor-status {
      background: #3b2e2e;
      color: var(--Red-600, #d83b3b);
      &:before {
        background-image: url("/assets/images/bridge/icn_warning_red.png");
      }
    }
  }
  &.light {
    color: #4a4a4a;
    &.is-success {
      ${RouterMain} {
        border: 1px solid var(--Spring-Green-200, #b2ffd8);
        background: var(--Spring-Green-50, #eefff6);
      }
    }
    &.is-fail {
      ${RouterMain} {
        border: 1px solid var(--Red-400, #f37676);
        background: var(--Red-50, #fef2f2);
      }
      .infor-status {
        background: var(--Red-100, #fde3e3);
        color: var(--Red-500, #e94a4a);
        &:before {
          background-image: url("/assets/images/bridge/icn_warning_red.png");
        }
      }
    }
  }
`;
export const ModalWrapper = styled.div`
  border-radius: 12px;
  background: #262626;
  padding: 30px 12px 20px;
  position: relative;
  margin-bottom: 14px;
  &:before {
    position: absolute;
    content: "";
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 47px;
    height: 5px;
    border-radius: 100px;
    background: #b2b2b2;
  }
  .logo-status,
  .title-status {
    text-align: center;
  }
  .logo-status {
    margin-bottom: 10px;
    img {
      border-radius: 15px;
      box-shadow: 0px 1px 19.4px 0px rgba(39, 113, 32, 0.17);
    }
  }
  .title-status {
    font-family: "Rethink Sans";
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  ${RouterMain} {
    border-radius: 8px;
    background: var(--Blue--Pri-950, #0e335d);
    padding: 10px;
    border-bottom: solid 1px #9e9e9e;
    padding-bottom: 12px;
    margin-bottom: 12px;
    &.light {
      background: var(--Blue--Pri-100, #d6f3ff);
      border: transparent;
    }
  }
  .infor-status {
    border-radius: 8px;
    background: #1c1c1e;
    color: #a4a4a4;
    font-family: "Rethink Sans";
    font-size: 15px;
    line-height: 125%; /* 18.75px */
    padding: 8px;
    position: relative;
    padding-left: 28px;
    &:before {
      position: absolute;
      content: "";
      top: 10px;
      left: 10px;
      background: url("/assets/images/bridge/icn_warning_grey.png") center /
        contain no-repeat;
      width: 13px;
      height: 13px;
    }
    &.light {
      background: var(--Neutral-50, #f7f7f8);
    }
  }
  &.light {
    border: 0.778px solid var(--Neutral-100, #eeeef0);
    background: var(--trionn-com-nero, #fff);
  }
`;
export const BlockButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  ${ButtonCommon} {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 70%;
    }
    &.is-full {
      width: 100%;
    }
  }
`;
