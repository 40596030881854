import styled from "styled-components";

export const CautionContainer = styled.div``;
export const CautionLoading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;
export const CautionFee = styled.div`
  padding: 0 10px 10px;
  & > p {
    color: red;
    font-size: 16px;
    font-weight: bold;
    font-family: "Rethink Sans", sans-serif;
  }
`;
export const CautionFeeItem = styled.div`
  color: #fff;
  font-family: "Rethink Sans", sans-serif;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  span {
    color: #0c88f5;
  }
`;
