import { Spin } from "antd";
import { useContext } from "react";
import {
  ConfirmBlockMain,
  ContentRouterDetail,
  ContentRouterNumber,
  LoggerCheck,
  LoggerContainer,
  RouterMain,
} from "../styled";
import { formatNumber } from "../../../../../utils/convertNumber";
import { ContextProviderWrapper } from "../../../../../components/Context";
import { Link } from "react-router-dom";
import externalLink from "../../../../../assets/Dashboard/Common/external_link_blue.svg";

const CrossDetailItem = ({
  item,
  index,
  stepLogs,
  dataConfirm,
  formatToTwoDecimals,
}: any) => {
  const stepLogItem = stepLogs.find((log: any) => log.step === index + 1);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  return (
    <ContentRouterDetail className="router-detail">
      {dataConfirm?.swaps?.length > 1 && (
        <ContentRouterNumber>
          <span>{index + 1}</span>
        </ContentRouterNumber>
      )}
      <ConfirmBlockMain>
        <div className="block-heading">
          <div className="block-heading-detail">
            {item?.internalSwaps?.length > 0 ? (
              <>
                <img
                  src={item.internalSwaps.at(-1)?.swapperLogo}
                  width={19}
                  height={19}
                  loading="lazy"
                  alt={item.name}
                />
                <h4 className="sub-title">
                  Change on {item.from.blockchain} to{" "}
                  {item.internalSwaps.at(-1)?.swapperId}
                </h4>
              </>
            ) : (
              <>
                <img
                  src={item.swapperLogo}
                  width={19}
                  height={19}
                  loading="lazy"
                  alt={item.name}
                />
                <h4 className="sub-title">
                  Convert on {item.to.blockchain} to {item.swapperId}
                </h4>
              </>
            )}
          </div>
        </div>
        <RouterMain className="has-logo-intermediary">
          <div className="block-from">
            <div className="block-logo">
              <img
                src={item.from.logo}
                alt="Logo Token"
                loading="lazy"
                className="logo-main"
                width={36}
                height={36}
              />
              <img
                src={item.from.blockchainLogo}
                alt="Logo Chain"
                loading="lazy"
                className="chain-logo"
                width={18}
                height={18}
              />
            </div>
            <div className="block-price">
              <span className="token">
                {formatToTwoDecimals(
                  Number(formatNumber(item?.fromAmount, item?.from?.decimals))
                )}
              </span>
              <span className="price">
                $
                {formatToTwoDecimals(
                  Number(formatNumber(item?.fromAmount, item?.from?.decimals)) *
                    item?.from?.usdPrice
                )}
              </span>
            </div>
          </div>
          <div className="line">
            <div className="block-internal-image">
              {item?.internalSwaps?.length > 0 ? (
                item?.internalSwaps?.map((inner: any, idx: number) => (
                  <img
                    key={idx}
                    src={inner.swapperLogo}
                    width={16}
                    height={16}
                    loading="lazy"
                    alt="logoIntermediary"
                  />
                ))
              ) : (
                <img
                  src={item.swapperLogo}
                  width={16}
                  height={16}
                  loading="lazy"
                  alt="logoIntermediary"
                />
              )}
            </div>
            <img
              src={`${
                !isMobile
                  ? "/assets/images/bridge/icn_line_07.svg"
                  : "/assets/images/bridge/icn_line_09.svg"
              }`}
              width={274}
              height={6}
              loading="lazy"
              alt="Line"
              className="road-line-detail"
            />
          </div>
          <div className="block-from">
            <div className="block-logo">
              <img
                src={item.to.logo}
                alt="Logo Token"
                loading="lazy"
                className="logo-main"
                width={36}
                height={36}
              />
              <img
                src={item.to.blockchainLogo}
                alt="Logo Chain"
                loading="lazy"
                className="chain-logo"
                width={18}
                height={18}
              />
            </div>
            <div className="block-price">
              <span className="token">
                {formatToTwoDecimals(Number(item?.toAmount))}
              </span>
              <span className="price">
                ${formatToTwoDecimals(item?.toAmount * item?.to?.usdPrice)}
              </span>
            </div>
          </div>
        </RouterMain>

        {/* Step logging*/}
        {stepLogItem && (
          <LoggerContainer>
            <h2>Detail log:</h2>
            <LoggerCheck>
              {stepLogItem && (
                <div className="step-log-container">
                  <p style={{ wordBreak: "break-word" }}>
                    {stepLogItem.logs[stepLogItem.logs.length - 1]}
                  </p>
                </div>
              )}
              {stepLogItem && stepLogItem.status === "pending" && (
                <div className="transaction-processing">
                  <p>
                    Transaction processing <Spin />
                  </p>
                </div>
              )}
              {stepLogItem && stepLogItem.status === "success" && (
                <>
                  {stepLogItem.explorerUrls.map((expl: any, indexExp: any) => {
                    return (
                      <div key={indexExp} className="transaction-success">
                        <p>{expl.description}</p>
                        <Link to={expl.url} target="_blank" rel="noreferrer">
                          <img src={externalLink} alt="icon" />
                        </Link>
                      </div>
                    );
                  })}
                </>
              )}
            </LoggerCheck>
          </LoggerContainer>
        )}
      </ConfirmBlockMain>
    </ContentRouterDetail>
  );
};

export default CrossDetailItem;
