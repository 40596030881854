import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ModalOverlay from "../../components/Common/ModalOverlay";
import TitleCommon from "../../components/Common/Title";
import TokenAndNetwork from "../Bridge/Modal/Token&Network";
import {
  SwapBenifitTag,
  SwapBg,
  SwapContainer,
  SwapExtension,
  SwapHeader,
  SwapInputValue,
  SwapWrapper,
} from "../Swap/styled";
import { ContextProviderWrapper } from "../../components/Context";
import {
  BlockContentSlippage,
  BridgeBottom,
  BridgeContainer,
  BridgeContainerInner,
  BridgeContent,
  BridgeContentHeading,
  BridgeContentInner,
  BridgeContentRouter,
  BridgeContentTable,
  BridgeInput,
  HoverSelectToken,
} from "../Bridge/styled";
import add_token_icon from "../../assets/Dashboard/Common/add_token_icon.svg";
import chart_light from "../../assets/Dashboard/Swap/chart_light.svg";
import chart from "../../assets/Dashboard/Swap/chart.svg";
import chart_active from "../../assets/Dashboard/Swap/chart_active.svg";
import settings from "../../assets/Dashboard/Swap/settings.svg";
import settings_active from "../../assets/Dashboard/Swap/settings_active.svg";
import settings_light from "../../assets/Dashboard/Swap/settings_light.svg";
import caret_down_light from "../../assets/Dashboard/Swap/caret_down_light.svg";
import { Controller, useForm } from "react-hook-form";
import InputCommon from "../../components/Common/Input";
import { ButtonCommon } from "../../Layout/styled";
import RouterV2 from "./RouterIemv2";
import {
  CreateTransactionRequest,
  RangoClient,
  TransactionStatus,
  TransactionType,
} from "rango-sdk";
import { useDebounce } from "../../hooks/useDebounce";
import ModalWallet from "../Bridge/Modal/Wallet";
import default_token_image from "../../assets/Dashboard/Common/default-token-image.png";
import {
  logApprovalResponse,
  logRouteStep,
  logStepStatus,
  logTransactionHash,
} from "../../utils/logger";
import { ethers, TransactionRequest } from "ethers";
import { shortenAddress } from "../../utils/addressUser";
import { Popover, Spin } from "antd";
import toast from "react-hot-toast";
import { KIBBLE_API } from "../../services/api";
import { useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import ConfirmBuyModal from "./Confirm/Modal";
import SettingsModalBridge from "./Settings";
import delay from "delay";
import { useAccount } from "wagmi";
import CustomToken from "./Settings/CustomToken";
import ModalStatus from "../Bridge/Modal/Status";
import { useLocation } from "react-router-dom";
import { Connection, VersionedTransaction } from "@solana/web3.js";
import CautionBlock from "./Caution";
import { StargateClient, SigningStargateClient } from "@cosmjs/stargate";
import { coins } from "@cosmjs/proto-signing";

declare const window: Window & typeof globalThis & { ethereum: any };

const BridgeV2 = () => {
  const KIB_API_RANGO: any = process.env.REACT_APP_KIBBLE_RANGO;
  const INPUT_DEBOUNCE = 500;
  const rango = new RangoClient(KIB_API_RANGO);
  const { theme, isDesktop, isMobile, setOpenModalWallet } = useContext(
    ContextProviderWrapper
  )!;
  const { control } = useForm({ mode: "onChange" });
  const [openFromModal, setOpenFromModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openAddToken, setOpenAddToken] = useState(false);
  const [nameChainFrom, setNameChainFrom] = useState({
    nameChainFrom: "",
    logoChainFrom: "",
    nameTokenFrom: "--",
    logoTokenFrom: "",
    priceTokenFrom: 0,
    nameSysbolFrom: "",
    addressFrom: "",
    typeChain: "EVM",
  });
  const [nameChainTo, setNameChainTo] = useState({
    nameChainTo: "",
    logoChainTo: "",
    nameTokenTo: "--",
    logoTokenTo: "",
    priceTokenTo: 0,
    nameChain: "",
    nameSysbolTo: "",
    addressTo: "",
    typeChain: "EVM",
  });
  const [pathRouter, setPathRouter] = useState<any>();
  const [dataTokenFrom, setDataTokenFrom] = useState<any>();
  const [openToModal, setOpenToModal] = useState(false);
  const [openModalReceipent, setOpenModalReceipent] = useState(false);
  const [dataTokenTo, setDataTokenTo] = useState<any>();
  const [valueInputFrom, setValueInputFrom] = useState<any>();
  const [isShowRouterMobile, setIsShowRouterMobile] = useState(false);
  const [isLoadingRouter, setIsLoadingRouter] = useState<boolean | null>(false);
  const [clickRouter, setClickRouter] = useState({
    id: 0,
    requestId: "",
  });
  const [receiveWallet, setReceiveWallet] = useState<string>("");
  const [selectedToData, setSelectedToData] = useState({
    amount: 0,
    usdPrice: 0,
  });
  const [chainList, setChainList] = useState<any>([]);
  const [balance, setBalance] = useState<any>();
  const [isLoadingBuy, setIsLoadingBuy] = useState(false);
  const [routerDetail, setRouterDetail] = useState<any>({
    totalTime: "",
    totalNetworkFee: "",
    intermediaryLogos: [],
  });
  const intervalRef = useRef<any>(null);
  //get token and price
  const [selectedData, setSelectedData] = useState<{
    token: string | null;
    price: string | null;
  }>({
    token: null,
    price: null,
  });
  const [isLoadingWalletDetail, setIsLoadingWalletDetail] = useState(false);
  const [balanceToken, setBalanceToken] = useState<any>();
  const [isLoadingBalanceToken, setIsLoadingBalanceToken] = useState(false);
  const [dataConfirm, setDataConfirm] = useState<any>();
  const [address, setAddress] = useState<string>();
  const [getDecimalsToken, setGetDecimalsToken] = useState(18);
  const [slippageTolerance, setSlippageTolerance] = useState<any>(
    localStorage.getItem("slippage")
  );
  const [slippageRouter, setSlippageRouter] = useState<any>(0);
  const [caution, setCaution] = useState([]);

  const handleSelectData = (token: string, price: string) => {
    setSelectedData({ token, price });
  };

  //format number
  const formatToTwoDecimals = (number: number) => {
    return Math.floor(number * 10000) / 10000;
  };
  const benifitTags = [
    {
      title: "Free gas",
      color: theme === "light" ? "#0FF586" : "#0FF586",
    },
    {
      title: "Lighting fast",
      color: theme === "light" ? "#E6A519" : "#F0CC54",
    },
    {
      title: "Best rate",
      color: theme === "light" ? "#DC622E" : "#DC622E",
    },
  ];

  //get router
  const getRouter = async () => {
    setIsLoadingRouter(true);
    const params = {
      from: JSON.stringify({
        blockchain: `${dataTokenFrom?.blockchain}`,
        symbol: `${dataTokenFrom?.symbol}`,
        address: dataTokenFrom?.address ? dataTokenFrom?.address : null,
      }),
      to: JSON.stringify({
        blockchain: `${dataTokenTo?.blockchain}`,
        symbol: `${dataTokenTo?.symbol}`,
        address: dataTokenTo?.address ? dataTokenTo?.address : null,
      }),
      amount: `${valueInputFrom}`,
      slippage: slippageTolerance,
    };
    const routingResponse = await KIBBLE_API.getRouting(params);
    if (routingResponse) {
      setPathRouter(routingResponse?.data);
      setIsLoadingRouter(false);
    }
    // console.log(routingResponse);
  };
  // const startPollingRouter = () => {
  //   if (intervalRef.current) {
  //     clearInterval(intervalRef.current); // Clear previous interval if exists
  //   }
  //   intervalRef.current = setInterval(() => {
  //     console.log("Fetching router...");
  //     getRouter();
  //   }, 20000); // 20s
  // };
  useEffect(() => {
    if (
      valueInputFrom &&
      Number(valueInputFrom) > 0 &&
      nameChainFrom.nameTokenFrom !== "--" &&
      nameChainTo.nameTokenTo !== "--"
    )
      // {
      //   startPollingRouter();
      // }

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
  }, [valueInputFrom, nameChainFrom, nameChainTo]);

  const debouncedHandleGetBridge = useDebounce(() => {
    getRouter();
    setIsShowRouterMobile(true);
  }, INPUT_DEBOUNCE);

  // Input change handler
  const onChangeInputFrom = (e: any) => {
    const newValue = e.target.value.replace(/[^0-9.]/g, "");
    setValueInputFrom(newValue);
  };

  // Trigger debounced function on input change
  useEffect(() => {
    if (
      valueInputFrom &&
      Number(valueInputFrom) > 0 &&
      nameChainFrom.nameTokenFrom !== "--" &&
      nameChainTo.nameTokenTo !== "--"
    ) {
      debouncedHandleGetBridge();
    }
  }, [valueInputFrom, nameChainFrom, nameChainTo]);

  // list extension
  const listExtensions = [
    {
      id: 3,
      icon: theme === "dark" ? add_token_icon : add_token_icon,
      activeIcon: add_token_icon,
    },
    {
      id: 1,
      icon: theme === "dark" ? chart : chart_light,
      activeIcon: chart_active,
    },
    {
      id: 2,
      icon: theme === "dark" ? settings : settings_light,
      activeIcon: settings_active,
    },
  ];

  // Function swap token
  const [confirmRouterTest, setConfirmRouterTest] = useState<any>();

  const connectedWallets = useSelector(
    (state: any) => state.accountSlice.referrals
  );

  const [selectedWallets, setSelectedWallets] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    const initialSelectedWallets = pathRouter?.results[clickRouter.id]?.swaps
      ?.flatMap((swap: any) => [swap.from.blockchain, swap.to.blockchain])
      .filter(
        (blockchain: any, index: any, self: any) =>
          self.indexOf(blockchain) === index
      )
      .map((blockchain: any) => {
        const existingAddress = selectedWallets
          ? selectedWallets[blockchain]
          : "";
        return { [blockchain]: existingAddress };
      })
      .reduce((acc: any, obj: any) => {
        return { ...acc, ...obj };
      }, {});

    setSelectedWallets(initialSelectedWallets);
  }, [pathRouter, clickRouter.id]);

  const updateSelectedWalletAddress = useCallback(
    (blockchain: any, address: any) => {
      setSelectedWallets((prev) => ({
        ...prev,
        [blockchain]: address,
      }));
    },
    []
  );

  const [loadingConfirmRouter, setLoadingConfirmRouter] = useState(false);
  const confirmRouter = async () => {
    setLoadingConfirmRouter(true);
    const params = {
      requestId: pathRouter?.results[clickRouter.id]?.requestId,
      selectedWallets: JSON.stringify(selectedWallets),
      // destination: "0x0038C4704Cb4FE96403b41d457FCB11c71111111",
    };

    const confirmResponse: any = await KIBBLE_API.confirmRouting(params);
    if (confirmResponse) {
      setConfirmRouterTest(confirmResponse?.data);
      setTimeout(() => {
        setLoadingConfirmRouter(false);
      }, 1000);
    }
    if (
      confirmResponse.error &&
      receiveWallet.length > 0 &&
      receiveWallet !== ""
    ) {
      toast.error(confirmResponse.error, { id: "walletError" });
      setTimeout(() => {
        setLoadingConfirmRouter(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (
      pathRouter &&
      pathRouter?.results?.length > 0
      // &&
      // selectedWallets[nameChainFrom?.nameChainFrom] !== ""
    ) {
      confirmRouter();
    }
  }, [selectedWallets, clickRouter]);

  const { chainId } = useAccount();
  const dataChainStore = useSelector(
    (state: any) => state.accountSlice.dataChain
  );

  const [loadingSwap, setLoadingSwap] = useState(false);
  const [stepLogs, setStepLogs] = useState<
    {
      step: number;
      logs: string[];
      status: string;
      hashes: any[];
      explorerUrls: any[];
    }[]
  >([]);

  const handleSwapCosmos = async (tx: any) => {
    console.log("transanction đây", tx);
    try {
      // const rpcEndpoint = "https://rpc.cosmoshub.strange.love"; // Replace with Cosmos RPC endpoint
      //   const mnemonic = "your-mnemonic"; // Replace with user mnemonic
      //   const wallet = await DirectSecp256k1Wallet.fromMnemonic(mnemonic, { prefix: "cosmos" });
      //   // const [account] = await wallet.getAccounts();
      //   const client = await SigningStargateClient.connectWithSigner(rpcEndpoint, wallet);
      //   // const msgTransfer = {
      //   //   typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
      //   //   value: {
      //   //     sourcePort: swap.value.source_port,
      //   //     sourceChannel: swap.value.source_channel,
      //   //     token: swap.value.token,
      //   //     sender: account.address,
      //   //     receiver: swap.value.receiver,
      //   //     timeoutHeight: swap.value.timeout_height,
      //   //     timeoutTimestamp: swap.value.timeout_timestamp,
      //   //   },
      //   // };
      //   const fee = {
      //     amount: coins(8750, "uatom"),
      //     gas: "350000",
      //   };
      //   const memo = tx.data.memo || "";
      //   const result = await client.signAndBroadcast("fromWalletAddress", [txmsgTransfer], fee, memo);
      //   if (result.code === 0) {
      //     console.log("Success")
      //     // updateStepLogs({
      //     //   step: currentStep,
      //     //   logs: ["Transaction succeeded."],
      //     //   status: "success",
      //     //   hashes: [{ hash: result.transactionHash, description: "Main transaction" }],
      //     // });
      //   } else {
      //     throw new Error(`Transaction failed: ${result.rawLog}`);
      //   }
    } catch (error) {
      console.log(error);
    }
  };

  const processSwapSteps = async () => {
    if (!confirmRouterTest?.result?.result?.swaps) {
      console.error("No swaps found in confirmRouterTest");
      setStepLogs((prevLogs) => [
        ...prevLogs,
        {
          step: 0,
          logs: ["No swaps found in confirmRouterTest"],
          status: "failed",
          hashes: [],
          explorerUrls: [],
        },
      ]);
      return;
    }
    setLoadingSwap(true);
    const swaps = confirmRouterTest.result.result.swaps;

    const updateStepLogs = (newLog: any) => {
      setStepLogs((prevLogs) => {
        const existingLogIndex = prevLogs.findIndex(
          (log) => log.step === newLog.step
        );
        if (existingLogIndex !== -1) {
          // Update existing log
          const updatedLogs = [...prevLogs];
          const mergedExplorerUrls = [
            ...updatedLogs[existingLogIndex].explorerUrls,
            ...(newLog.explorerUrls || []),
          ];
          // Remove duplicate URLs
          const uniqueExplorerUrls = mergedExplorerUrls.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.url === item.url)
          );
          updatedLogs[existingLogIndex] = {
            ...updatedLogs[existingLogIndex],
            ...newLog,
            logs: [...updatedLogs[existingLogIndex].logs, ...newLog.logs],
            hashes: [
              ...updatedLogs[existingLogIndex].hashes,
              ...(newLog.hashes || []),
            ],
            explorerUrls: uniqueExplorerUrls,
          };
          return updatedLogs;
        } else {
          return [...prevLogs, newLog];
        }
      });
    };

    let hashApprove: string | null = null;

    for (let i = 0; i < swaps.length; i++) {
      const currentStep = i + 1;
      updateStepLogs({
        step: currentStep,
        logs: [`Starting step ${currentStep}...`],
        status: "pending",
        hashes: [],
        explorerUrls: [],
      });
      const swap = swaps[i];
      logRouteStep(swap, currentStep);

      const request: CreateTransactionRequest = {
        requestId: confirmRouterTest.result.requestId,
        step: currentStep,
        userSettings: {
          slippage: slippageTolerance,
          infiniteApprove: false,
        },
        validations: {
          approve: true,
          balance: true,
          fee: true,
        },
      };

      try {
        // let createTransactionResponse: any = await rango.createTransaction(
        //   request
        // );
        let createTransactionResponse: any = await KIBBLE_API.createTx(request);
        let tx = createTransactionResponse?.data.transaction;

        console.log("createTransactionResponse", createTransactionResponse);

        if (createTransactionResponse?.data.error) {
          toast.error(createTransactionResponse?.data.error);
          setLoadingSwap(false);
          setStepLogs([]);
          return;
        }

        if (!tx) {
          throw new Error(
            `Error creating the transaction: ${createTransactionResponse.error}`
          );
        }

        // Handle approval transaction
        if (tx.type === TransactionType.EVM) {
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer: any = await provider.getSigner();
          // Switch Chain
          const chainInfo = dataChainStore.find(
            (chain: any) => chain.name === tx.blockChain
          );

          const switchChain = async (chainId: number) => {
            const hexChainId = `0x${chainId.toString(16)}`;

            try {
              await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: hexChainId }],
              });
              console.log(`Successfully switched to chain ID: ${chainId}`);
            } catch (error: any) {
              console.log(error);
            }
          };

          if (chainInfo) {
            const chainIDNumber = parseInt(chainInfo.chainId, 16);
            if (chainId !== chainIDNumber) {
              // console.log(`Switching from chain ${chainId}`);
              await switchChain(chainIDNumber);
              // console.log(`Switched to chain ID: ${chainIDNumber}`);
            }
          }

          // const approveTransaction: any = {
          //   from: tx.from,
          //   to: tx.to,
          //   data: tx.data,
          //   value: tx.value,
          //   maxFeePerGas: tx.maxFeePerGas,
          //   maxPriorityFeePerGas: tx.maxPriorityFeePerGas,
          //   gasPrice: tx.gasPrice,
          //   gasLimit: tx.gasLimit,
          // };

          if (chainInfo) {
            const chainIDNumber = parseInt(chainInfo.chainId, 16);
            if (chainId !== chainIDNumber) {
              // console.log(`Switching from chain ${chainId}`);
              await switchChain(chainIDNumber);
              // console.log(`Switched to chain ID: ${chainIDNumber}`);
            }
          }

          if (tx.isApprovalTx) {
            const approveTransaction: any = {
              from: tx.from,
              to: tx.to,
              data: tx.data,
              value: tx.value,
              maxFeePerGas: tx.maxFeePerGas,
              maxPriorityFeePerGas: tx.maxPriorityFeePerGas,
              gasPrice: tx.gasPrice,
              gasLimit: tx.gasLimit,
            };

            const { hash } = await signer.sendTransaction(approveTransaction);
            hashApprove = hash;
            logTransactionHash(hash, true);

            updateStepLogs({
              step: currentStep,
              logs: [`Approval transaction sent`],
              hashes: [{ hashApprove, description: "Approval transaction" }],
            });
          }

          console.log("hashApprove", hashApprove);

          // Wait for approval
          while (true) {
            await delay(5000);
            const approvalStatus: any = await KIBBLE_API.checkApproval({
              requestId: confirmRouterTest.result.requestId,
              txId: hashApprove,
            });

            logApprovalResponse(approvalStatus?.data.isApproved);

            if (approvalStatus?.data.isApproved) {
              updateStepLogs({
                step: currentStep,
                logs: ["Approval confirmed."],
              });
              break;
            } else if (
              approvalStatus?.data.txStatus === TransactionStatus.FAILED
            ) {
              toast.error("Approval transaction failed in blockchain", {
                id: "error-1",
              });
            }
          }

          updateStepLogs({
            step: currentStep,
            logs: ["Sending main transaction..."],
          });

          // Create the main transaction
          // createTransactionResponse = await rango.createTransaction(request);
          createTransactionResponse = await KIBBLE_API.createTx(request);
          tx = createTransactionResponse?.data.transaction;

          if (!tx || tx.type !== TransactionType.EVM) {
            toast.error(`${createTransactionResponse.error}`, {
              id: "error-2",
            });
          }

          // Send the main transaction
          const mainTransaction: TransactionRequest = {
            from: tx.from,
            to: tx.to,
            data: tx.data,
            value: tx.value,
            maxFeePerGas: tx.maxFeePerGas,
            maxPriorityFeePerGas: tx.maxPriorityFeePerGas,
            gasPrice: tx.gasPrice,
            gasLimit: tx.gasLimit,
          };

          const { hash: hashMain } = await signer.sendTransaction(
            mainTransaction
          );

          updateStepLogs({
            step: currentStep,
            logs: [`Main transaction sent`],
            hashes: [{ hashMain, description: "Main transaction" }],
          });

          logTransactionHash(hashMain, false);

          // Check transaction status
          while (true) {
            await delay(5000);
            // const state: any = await rango.checkStatus({
            const state: any = await rango.checkStatus({
              requestId: confirmRouterTest.result.requestId,
              step: currentStep,
              txId: hashMain,
            });

            logStepStatus(state);

            updateStepLogs({
              step: currentStep,
              logs: [`Status updated: ${state.status}`],
              explorerUrls: state.explorerUrl || [],
            });

            if (state.status === TransactionStatus.SUCCESS) {
              updateStepLogs({
                step: currentStep,
                logs: ["Transaction succeeded."],
                status: "success",
              });
              break;
            } else if (state.status === TransactionStatus.FAILED) {
              throw new Error(`Swap failed on step ${currentStep}`);
            }
          }
        } else if (tx.type === TransactionType.SOLANA) {
          const solanaProvider = window?.phantom?.solana;

          if (!solanaProvider || !solanaProvider.isPhantom) {
            console.error("Phantom Wallet not found.");
            return;
          }

          await solanaProvider.connect();

          const connection = new Connection(
            "https://green-warmhearted-putty.solana-mainnet.quiknode.pro/530bd2a41cc356062e5cd8b0e69d1fc9bda27d30",
            "confirmed"
          );

          const serializedMessage = Uint8Array.from(tx.serializedMessage);
          const transaction =
            VersionedTransaction.deserialize(serializedMessage);

          // Ensure transaction signatures array is initialized
          if (!transaction.signatures || transaction.signatures.length === 0) {
            transaction.signatures = [];
          }

          // Sign the transaction with Phantom Wallet
          const signedTransaction = await solanaProvider.signTransaction(
            transaction
          );

          // Send the signed transaction
          const signature = await connection.sendRawTransaction(
            signedTransaction.serialize()
          );

          // Log transaction hash
          updateStepLogs({
            step: currentStep,
            logs: ["Transaction sent to Solana blockchain"],
            hashes: [{ hash: signature, description: "Main transaction" }],
          });

          // logTransactionHash(hash, false);

          // Track swap status
          while (true) {
            await delay(5000);
            const state = await rango.checkStatus({
              requestId: confirmRouterTest.result.requestId,
              step: currentStep,
              txId: signature,
            });

            logStepStatus(state);

            updateStepLogs({
              step: currentStep,
              logs: [`Status updated: ${state.status}`],
              explorerUrls: state.explorerUrl || [],
            });

            if (state.status === TransactionStatus.SUCCESS) {
              updateStepLogs({
                step: currentStep,
                logs: ["Transaction succeeded."],
                status: "success",
              });
              break;
            } else if (state.status === TransactionStatus.FAILED) {
              setLoadingSwap(false);
              setStepLogs([]);
              throw new Error(`Swap failed on step ${currentStep}`);
            }
          }
        } else if (tx.type === TransactionType.COSMOS) {
          handleSwapCosmos(tx);
        }
      } catch (error: any) {
        console.error(`Error processing swap step ${currentStep}:`, error);
        toast.error(error?.info?.error?.message);
        setLoadingSwap(false);
        // setStepLogs([]);
        break;
      }
    }
  };

  useEffect(() => {
    // Check if all stepLogs have status "success"
    const allSuccess = stepLogs.every((log: any) => log.status === "success");

    if (allSuccess && stepLogs.length > 0) {
      setLoadingSwap(false);
      // setStepLogs([]);
    }
  }, [stepLogs]);

  useEffect(() => {
    if (!openConfirmModal) {
      setStepLogs([]);
    }
  }, [openConfirmModal]);

  //get wallet address
  useEffect(() => {
    if (connectedWallets?.length > 0) {
      const evmWallet = connectedWallets.find(
        (wallet: any) => wallet.type === "EVM"
      );

      if (!evmWallet) {
        // console.log("No EVM wallet connected");
        setIsLoadingBalanceToken(false);
        setAddress(undefined);
        return;
      }
      const evmAddress = evmWallet.address;
      if (address !== evmAddress) {
        setAddress(evmAddress);
      }
    }
  }, [connectedWallets]);

  const getWalletDetails = async () => {
    setIsLoadingWalletDetail(true);
    try {
      if (!chainList) return;
      const evmChains = chainList.filter(
        (chain: any) => chain.type === "EVM" && chain.name
      );
      const filteredChains = evmChains.length > 0 ? evmChains : chainList;
      const queryAddresses = filteredChains.map((chain: any, index: number) =>
        index === 0
          ? `?address=${chain.name}.${address}`
          : `&address=${chain.name}.${address}`
      );
      const params = {
        chain: queryAddresses.toString().replaceAll(",", ""),
      };
      const response = await KIBBLE_API.getBalanceMultichain(params);
      setBalance(response?.data?.wallets);
      setIsLoadingWalletDetail(false);
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      setIsLoadingWalletDetail(false);
    }
  };
  useEffect(() => {
    if (chainList?.length > 0 && address) {
      getWalletDetails();
      setBalanceToken(0);
    }
  }, [chainList?.length, address]);

  //get balance when select
  const fetchTokenBalance = async () => {
    setIsLoadingBalanceToken(true);
    try {
      const evmWallet = connectedWallets.find(
        (wallet: any) => wallet.type === "EVM"
      );
      if (!evmWallet) {
        console.warn("No EVM wallet connected");
        setIsLoadingBalanceToken(false);
        return;
      }
      const evmAddress = evmWallet.address;

      // Call API
      const response = await KIBBLE_API.getTokenBalance({
        evmAddress,
        blockchain: nameChainFrom.nameChainFrom,
        symbol: nameChainFrom.nameSysbolFrom,
        address: nameChainFrom.addressFrom,
        apiKey: KIB_API_RANGO,
      });

      const balanceBignumber = new BigNumber(response?.data?.balance || 0);
      const tokenBalance = balanceBignumber
        .dividedBy(new BigNumber(10).pow(getDecimalsToken))
        .toFixed(6);
      setBalanceToken(tokenBalance);
      setIsLoadingBalanceToken(false);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      setIsLoadingBalanceToken(false);
    }
  };

  useEffect(() => {
    if (connectedWallets?.length > 0 && nameChainFrom?.priceTokenFrom) {
      fetchTokenBalance();
    }
  }, [connectedWallets, nameChainFrom]);

  //content-hover
  const content = (
    <HoverSelectToken>
      <p>Please select chain and token</p>
    </HoverSelectToken>
  );

  // Catch wallet address based on chain selected
  const handleCatchPayWallet = (typeChain: any, newAddress?: string) => {
    const formattedTypeChain = typeChain === "SOLANA" ? "Solana" : typeChain;

    const updatedWallets = connectedWallets.map((item: any) =>
      item.type === formattedTypeChain && newAddress
        ? { ...item, address: newAddress }
        : item
    );

    if (
      newAddress &&
      !connectedWallets.some((item: any) => item.type === formattedTypeChain)
    ) {
      updatedWallets.push({ type: formattedTypeChain, address: newAddress });
    }

    const addressCatch = updatedWallets.find(
      (item: any) => item.type === formattedTypeChain
    )?.address;

    return addressCatch ? shortenAddress(addressCatch) : "--";
  };

  useEffect(() => {
    if (nameChainFrom.typeChain) {
      handleCatchPayWallet(nameChainFrom.typeChain);
    }
  }, [nameChainFrom]);

  // get current URL
  const location = useLocation();
  useEffect(() => {
    if (location?.search) {
      const queryParams: any = new URLSearchParams(location.search);
      const fromAmount = queryParams.get("fromAmount");
      const dataFromLocal: any = localStorage.getItem("dataFrom");
      const dataFromCoverJson = JSON.parse(dataFromLocal);
      const dataToLocal: any = localStorage.getItem("dataTo");
      const dataToCoverJson = JSON.parse(dataToLocal);
      console.log(dataFromCoverJson?.blockchainImage);
      setNameChainFrom((prevState) => ({
        ...prevState,
        nameChainFrom: queryParams?.get("fromBlockchain"),
        nameSysbolFrom: queryParams?.get("fromToken"),
        logoChainFrom: dataFromCoverJson?.blockchainImage,
        logoTokenFrom: dataFromCoverJson?.image,
        priceTokenFrom: dataFromCoverJson?.usdPrice,
      }));
      setDataTokenFrom((prevState: any) => ({
        ...prevState,
        blockchain: queryParams.get("fromBlockchain"),
        symbol: queryParams?.get("fromToken")?.split("--")[0],
        address: queryParams?.get("fromToken")?.split("--")[1] || null,
      }));

      setNameChainTo((prevState) => ({
        ...prevState,
        nameChainTo: queryParams?.get("toBlockchain"),
        nameSysbolTo: queryParams?.get("toToken"),
        logoChainTo: dataToCoverJson?.blockchainImage,
        logoTokenTo: dataToCoverJson?.image,
      }));
      setDataTokenTo((prevState: any) => ({
        ...prevState,
        blockchain: queryParams.get("toBlockchain"),
        symbol: queryParams?.get("toToken")?.split("--")[0],
        address: queryParams?.get("toToken")?.split("--")[1] || null,
      }));
      setValueInputFrom(fromAmount);
    }
  }, []);

  useEffect(() => {
    if (
      location?.search &&
      dataTokenFrom &&
      dataTokenTo &&
      valueInputFrom > 0
    ) {
      // getRouter();
      // setIsShowRouterMobile(true);
      debouncedHandleGetBridge();
    }
  }, [dataTokenFrom, dataTokenTo, valueInputFrom]);

  useEffect(() => {
    if (location?.search && dataTokenFrom) {
      fetchTokenBalance();
    }
  }, [dataTokenFrom]);

  return (
    <SwapBg>
      {/* Confirm Buy */}
      <ModalOverlay
        component={
          <ConfirmBuyModal
            dataConfirm={dataConfirm}
            routerDetail={routerDetail}
            handleRequestTransaction={processSwapSteps}
            isLoadingBuy={isLoadingBuy}
            stepLogs={stepLogs}
            loadingSwap={loadingSwap}
          />
        }
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        title={"Confirmation"}
        width="550px"
        pathRouter={pathRouter}
      />
      {/* Modal status */}
      <ModalOverlay
        component={
          <ModalStatus
            dataConfirm={dataConfirm}
            formatToTwoDecimals={formatToTwoDecimals}
          />
        }
        open={false}
        title={"cross chain"}
        width="470px"
      />
      {/* ----------- */}
      {/* Settings */}
      <ModalOverlay
        component={
          <SettingsModalBridge
            setSlippageTolerance={setSlippageTolerance}
            slippageTolerance={slippageTolerance}
            setOpenModal={setOpenSettingsModal}
          />
        }
        open={openSettingsModal}
        setOpen={setOpenSettingsModal}
        title={"Settings"}
        width="450px"
      />
      {/* ----------- */}
      {/* Add token */}
      <ModalOverlay
        component={<CustomToken />}
        open={openAddToken}
        setOpen={setOpenAddToken}
        title={"Add new token"}
        width="450px"
      />
      {/* ----------- */}
      <ModalOverlay
        component={
          <TokenAndNetwork
            isFrom={true}
            setNameChain={setNameChainFrom}
            setOpenModal={setOpenFromModal}
            setDataToken={setDataTokenFrom}
            setChainList={setChainList}
            chainList={chainList}
            balances={balance}
            onSelectData={handleSelectData}
            address={address}
            isLoadingWalletDetail={isLoadingWalletDetail}
            connectedWallets={connectedWallets}
            setGetDecimalsToken={setGetDecimalsToken}
            valueInputFrom={valueInputFrom}
            dataChainFrom={nameChainFrom}
          />
        }
        open={openFromModal}
        setOpen={setOpenFromModal}
        title={"Token & Network"}
        width="550px"
      />
      <ModalOverlay
        component={
          <TokenAndNetwork
            isFrom={false}
            setNameChain={setNameChainTo}
            setOpenModal={setOpenToModal}
            setDataToken={setDataTokenTo}
            setChainList={setChainList}
            chainList={chainList}
            balances={balance}
            isLoadingWalletDetail={isLoadingWalletDetail}
            connectedWallets={connectedWallets}
            setGetDecimalsToken={setGetDecimalsToken}
            dataChainTo={nameChainTo}
            valueInputFrom={valueInputFrom}
          />
        }
        open={openToModal}
        setOpen={setOpenToModal}
        title={"Token & Network"}
        width="550px"
      />
      <ModalOverlay
        component={
          <ModalWallet
            nameChainTo={nameChainTo}
            setReceiveWallet={setReceiveWallet}
            setOpenModalReceipent={setOpenModalReceipent}
            receiveWallet={receiveWallet}
            swapsRouter={pathRouter?.results[clickRouter?.id]?.swaps}
            selectedWallets={selectedWallets}
            updateSelectedWalletAddress={updateSelectedWalletAddress}
            confirmRouter={confirmRouterTest?.result?.result?.swaps}
            handleCatchPayWallet={handleCatchPayWallet}
          />
        }
        open={openModalReceipent}
        setOpen={setOpenModalReceipent}
        title={"Receive Wallet"}
        width="550px"
      />
      <BridgeContainerInner>
        <SwapContainer>
          <TitleCommon text={"POWERFUL BRIDGE"} />
          <SwapBenifitTag className={theme}>
            {benifitTags.map((item, index) => {
              return (
                <li
                  style={{
                    color: item.color,
                  }}
                  key={index}
                >
                  <span
                    style={{
                      background: item.color,
                    }}
                  ></span>
                  {item.title}
                </li>
              );
            })}
          </SwapBenifitTag>
          <BridgeContentTable>
            <BridgeContainer className="bridge-container-left">
              <SwapWrapper className={theme}>
                <SwapHeader>
                  <TitleCommon
                    style={{
                      marginBottom: "0",
                    }}
                    text={"CROSS CHAIN"}
                  />
                  <SwapExtension className={theme}>
                    {listExtensions.map((item, index) => {
                      return (
                        <li
                          onClick={() => {
                            if (item.id === 2) {
                              setOpenSettingsModal(true);
                            } else if (item.id === 3) {
                              setOpenAddToken(true);
                            }
                          }}
                          key={index}
                        >
                          <img src={item.icon} alt="icon" loading="lazy" />
                        </li>
                      );
                    })}
                  </SwapExtension>
                </SwapHeader>
                <BridgeContent>
                  <BridgeContentInner>
                    <BridgeContentHeading className={theme}>
                      <div className="block-left">
                        <p>
                          Pay:{" "}
                          <span className="is-address">
                            {handleCatchPayWallet(nameChainFrom.typeChain)}
                          </span>
                        </p>
                        <img
                          className="arrow-address"
                          src={caret_down_light}
                          width={17}
                          height={15}
                          loading="lazy"
                          alt="Icon Arrow"
                        />
                      </div>
                      <Popover
                        content={content}
                        title="Select Chain and Token"
                        trigger="hover"
                      >
                        <div className="block-right">
                          <div
                            className="select-token"
                            onClick={() => {
                              setOpenFromModal(true);
                            }}
                          >
                            <div className="logo-token">
                              {nameChainFrom.logoTokenFrom ? (
                                <img
                                  src={nameChainFrom.logoTokenFrom}
                                  width={23}
                                  height={23}
                                  loading="lazy"
                                  alt="Icon"
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              ) : (
                                <div className="logo-token">
                                  <img
                                    width={23}
                                    height={23}
                                    src={default_token_image}
                                    alt="empty"
                                  />
                                </div>
                              )}
                            </div>
                            <p className="name-token">
                              {nameChainFrom.nameSysbolFrom
                                ? nameChainFrom.nameSysbolFrom?.split("--")[0]
                                : "--"}
                            </p>
                            <div className="icon-arrow-token">
                              <img
                                src={caret_down_light}
                                width={17}
                                height={15}
                                loading="lazy"
                                alt="Icon Arrow"
                              />
                            </div>
                          </div>
                          <div className="block-chain">
                            {nameChainFrom.logoTokenFrom ? (
                              <img
                                src={
                                  nameChainFrom.logoTokenFrom &&
                                  !nameChainFrom.logoChainFrom
                                    ? "/assets/images/bridge/icn_chain_bsc.svg"
                                    : nameChainFrom.logoChainFrom
                                }
                                width={17}
                                height={17}
                                loading="lazy"
                                alt="Icon chain"
                              />
                            ) : (
                              <div className="logo-token">
                                <img
                                  width={23}
                                  height={23}
                                  src={default_token_image}
                                  alt="empty"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Popover>
                    </BridgeContentHeading>
                    <BridgeInput className={theme}>
                      <Controller
                        name="fromAmount"
                        control={control}
                        render={({ field }: any) => (
                          <SwapInputValue>
                            <InputCommon
                              {...field}
                              // disabled={address && checkingToken ? false : true}
                              // onChange={handleSendValue}
                              // onFocus={handleResetValueFrom}
                              placeHolder={"0"}
                              value={valueInputFrom}
                              onChange={onChangeInputFrom}
                              type="number"
                            />
                          </SwapInputValue>
                        )}
                      />
                    </BridgeInput>
                    <BridgeBottom className={theme}>
                      <div className="content-left">
                        <p className="number-token">
                          {valueInputFrom
                            ? `~${formatToTwoDecimals(
                                nameChainFrom.priceTokenFrom *
                                  Number(valueInputFrom)
                              )}`
                            : "0.0"}
                          <span className="token"> USD</span>
                        </p>
                      </div>
                      <div className="content-right">
                        <p>
                          Bal:{" "}
                          {isLoadingBalanceToken ? (
                            <Spin />
                          ) : (
                            formatToTwoDecimals(balanceToken) || "0"
                          )}
                          <span
                            className="max"
                            onClick={() =>
                              setValueInputFrom(balanceToken ? balanceToken : 0)
                            }
                          >
                            Max
                          </span>
                        </p>
                      </div>
                    </BridgeBottom>
                  </BridgeContentInner>
                </BridgeContent>
                {isMobile && (
                  <BridgeContentRouter className={theme}>
                    <div
                      className="bridge-content-router-inner"
                      onClick={() =>
                        !isDesktop &&
                        pathRouter?.results?.length > 0 &&
                        setIsShowRouterMobile(true)
                      }
                    >
                      <div className="router-left">
                        <p className="text-left">
                          <span>Fee</span>
                          {routerDetail.totalNetworkFee
                            ? `$${formatToTwoDecimals(
                                routerDetail.totalNetworkFee
                              )}`
                            : 0}
                        </p>
                        {/* <img
                          src="/assets/images/bridge/icn_down_small_white.svg"
                          width={14}
                          height={14}
                          loading="lazy"
                          alt=""
                        /> */}
                      </div>
                      <div className="router-right">
                        <div className="router-middle">
                          <img
                            src="/assets/images/bridge/icn_down_white.svg"
                            width={24}
                            height={24}
                            loading="lazy"
                            alt="Icon Arrow Down"
                          />
                        </div>
                        <div className="router-right-inner">
                          <div className="block-images">
                            {routerDetail?.intermediaryLogos?.length > 0 ? (
                              routerDetail?.intermediaryLogos?.map(
                                (item: any, index: number) => (
                                  <img
                                    key={index}
                                    src={item}
                                    width={28}
                                    height={28}
                                    loading="lazy"
                                    alt=""
                                  />
                                )
                              )
                            ) : (
                              <div className="logo-token">
                                <img
                                  width={23}
                                  height={23}
                                  src={default_token_image}
                                  alt="empty"
                                />
                              </div>
                            )}
                          </div>
                          <div className="block-steps">
                            <p>
                              {pathRouter ? pathRouter?.results?.length : 0}{" "}
                              Stops{" "}
                              <img
                                src={`${
                                  theme === "light"
                                    ? "/assets/images/bridge/icn_down_small_grey.svg"
                                    : "/assets/images/bridge/icn_down_small_white.svg"
                                }`}
                                width={14}
                                height={14}
                                loading="lazy"
                                alt=""
                              />
                            </p>
                          </div>
                          <div className="block-time">
                            <p>
                              {routerDetail.totalTime
                                ? routerDetail.totalTime
                                : 0}
                              s
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </BridgeContentRouter>
                )}
                <BridgeContent>
                  <BridgeContentInner>
                    <BridgeContentHeading className={theme}>
                      <div
                        className="block-left"
                        onClick={() => {
                          if (
                            nameChainTo?.nameChainTo !== "" &&
                            nameChainFrom?.nameChainFrom !== "" &&
                            pathRouter?.results?.length > 0
                          ) {
                            setOpenModalReceipent(true);
                          }
                        }}
                      >
                        <p>
                          Receive:{" "}
                          <span className="is-address">
                            {!receiveWallet
                              ? handleCatchPayWallet(nameChainTo.typeChain)
                              : shortenAddress(receiveWallet)}
                          </span>
                        </p>
                        <img
                          className="arrow-address"
                          src={caret_down_light}
                          width={17}
                          height={15}
                          loading="lazy"
                          alt="Icon Arrow"
                        />
                      </div>
                      <Popover
                        content={content}
                        title="Select Chain and Token"
                        trigger="hover"
                      >
                        <div className="block-right">
                          <div
                            className="select-token"
                            onClick={() => {
                              setOpenToModal(true);
                            }}
                          >
                            <div className="logo-token">
                              {nameChainTo.logoTokenTo ? (
                                <img
                                  src={nameChainTo.logoTokenTo}
                                  width={23}
                                  height={23}
                                  loading="lazy"
                                  alt="Icon"
                                />
                              ) : (
                                <div className="logo-token">
                                  <img
                                    width={23}
                                    height={23}
                                    src={default_token_image}
                                    alt="empty"
                                  />
                                </div>
                              )}
                            </div>
                            <p className="name-token">
                              {nameChainTo.nameSysbolTo
                                ? nameChainTo.nameSysbolTo?.split("--")[0]
                                : "--"}
                            </p>
                            <div className="icon-arrow-token">
                              <img
                                src={caret_down_light}
                                width={17}
                                height={15}
                                loading="lazy"
                                alt="Icon Arrow"
                              />
                            </div>
                          </div>
                          <div className="block-chain">
                            {nameChainTo.logoTokenTo ? (
                              <img
                                src={
                                  nameChainTo.logoTokenTo &&
                                  !nameChainTo.logoChainTo
                                    ? "/assets/images/bridge/icn_chain_bsc.svg"
                                    : nameChainTo.logoChainTo
                                }
                                width={17}
                                height={17}
                                loading="lazy"
                                alt="Icon chain"
                              />
                            ) : (
                              <div className="logo-token">
                                <img
                                  width={23}
                                  height={23}
                                  src={default_token_image}
                                  alt="empty"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Popover>
                    </BridgeContentHeading>
                    <BridgeInput className={`bridge-input-to ${theme}`}>
                      <Controller
                        name="fromTo"
                        control={control}
                        render={({ field }: any) => (
                          <SwapInputValue>
                            <InputCommon
                              {...field}
                              disabled={true}
                              // onChange={handleSendValue}
                              // onFocus={handleResetValueFrom}
                              placeHolder={"0"}
                              value={selectedToData?.amount}
                              // onChange={onChangeInputFrom}
                            />
                          </SwapInputValue>
                        )}
                      />
                    </BridgeInput>
                    <BridgeBottom className={theme}>
                      <div className="content-left">
                        <p className="number-token">
                          {selectedToData?.amount > 0
                            ? `~${formatToTwoDecimals(
                                selectedToData?.amount *
                                  selectedToData?.usdPrice
                              )}`
                            : "0.0"}{" "}
                          <span className="token">USD</span>
                        </p>
                      </div>
                      {/* <div className="content-right">
                        <p>
                          Bal: $12,053.32<span>Max</span>
                        </p>
                      </div> */}
                    </BridgeBottom>
                  </BridgeContentInner>
                </BridgeContent>
                {pathRouter &&
                slippageTolerance <
                  Number(slippageRouter?.recommendedSlippage?.slippage) ? (
                  <BlockContentSlippage>
                    <div className="slippage-left">
                      <div className="icon-warning">
                        <img
                          src="/assets/images/bridge/icn_warning.png"
                          width={16}
                          height={16}
                          loading="lazy"
                          alt="Warning"
                        />
                      </div>
                      <p className="text-warning">
                        We recommend increasing the slip to at least{" "}
                        {slippageRouter?.recommendedSlippage?.slippage} for this
                        route.
                      </p>
                    </div>
                    <button
                      className="button-change"
                      onClick={() => setOpenSettingsModal(true)}
                    >
                      Change
                    </button>
                  </BlockContentSlippage>
                ) : pathRouter &&
                  slippageTolerance -
                    Number(slippageRouter?.recommendedSlippage?.slippage) >=
                    4.5 ? (
                  <>
                    <BlockContentSlippage>
                      <div className="slippage-left">
                        <div className="icon-warning">
                          <img
                            src="/assets/images/bridge/icn_warning.png"
                            width={16}
                            height={16}
                            loading="lazy"
                            alt="Warning"
                          />
                        </div>
                        <p className="text-warning">
                          Be careful, your slip is quite high.
                        </p>
                      </div>
                      <button
                        className="button-change"
                        onClick={() => setOpenSettingsModal(true)}
                      >
                        Change
                      </button>
                    </BlockContentSlippage>
                  </>
                ) : null}
                {/* Caution */}
                <CautionBlock
                  confirmRouter={confirmRouterTest?.result?.result?.swaps}
                  loadingConfirmRouter={loadingConfirmRouter}
                  valueInputFrom={valueInputFrom}
                  setCaution={setCaution}
                  caution={caution}
                />
                {/* ------- */}
                {connectedWallets && connectedWallets.length > 0 ? (
                  <ButtonCommon
                    className="button-buy"
                    onClick={() => {
                      if (confirmRouterTest?.ok) {
                        setOpenConfirmModal(true);
                      }
                    }}
                    disabled={confirmRouterTest?.ok ? false : true}
                  >
                    {isLoadingBuy && valueInputFrom > 0 ? (
                      <Spin size="small" />
                    ) : (
                      <p>
                        {caution && caution?.length > 0
                          ? "Proceed anyway "
                          : "Swap"}
                      </p>
                    )}
                  </ButtonCommon>
                ) : (
                  <ButtonCommon
                    className="button-buy"
                    onClick={() => {
                      setOpenModalWallet(true);
                    }}
                  >
                    <p>Connect Wallet</p>
                  </ButtonCommon>
                )}
              </SwapWrapper>
            </BridgeContainer>
            <BridgeContainer
              className={`bridge-container-right ${
                isShowRouterMobile ? "is-show" : ""
              }`}
            >
              <RouterV2
                pathRouter={pathRouter}
                setIsShowRouterMobile={setIsShowRouterMobile}
                isLoadingRouter={isLoadingRouter}
                setClickRouter={setClickRouter}
                clickRouter={clickRouter}
                setSelectedToData={setSelectedToData}
                setRouterDetail={setRouterDetail}
                setDataConfirm={setDataConfirm}
                setSlippageRouter={setSlippageRouter}
              />
            </BridgeContainer>
          </BridgeContentTable>
        </SwapContainer>
      </BridgeContainerInner>
    </SwapBg>
  );
};

export default BridgeV2;
