import { useAccount, useConnect, useDisconnect } from "wagmi";
import { ButtonWallet, WalletConnectedTag } from "../styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addConnectedWallet,
  removeConnectedWallet,
} from "../../../../store/features/accountSlice";

const EVMWalletList = ({
  searchText,
  localConnectedWallets,
  setLocalConnectedWallets,
}: any) => {
  const { connect, connectors } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { isConnected, connector: activeConnector, address } = useAccount();
  const dispatch = useDispatch();
  const connectedWallets = useSelector(
    (state: any) => state.accountSlice.referrals
  );
  const [autoReconnectTriggered, setAutoReconnectTriggered] = useState(false);

  const uniqueConnectors = connectors
    .filter(
      (connector, index, self) =>
        index === self.findIndex((c) => c.id === connector.id)
    )
    .filter(
      (connector) => connector.id !== "injected" && connector.id !== "ID_AUTH"
    )
    .filter(
      (connector) =>
        !searchText ||
        connector.name.toLowerCase().includes(searchText.toLowerCase())
    );

  const saveToLocalStorage = (wallet: any) => {
    const existingWallets = JSON.parse(
      localStorage.getItem("lastConnectedWallet") || "[]"
    );
    const updatedWallets = [
      ...existingWallets.filter((w: any) => w.id !== wallet.id),
      wallet,
    ];
    localStorage.setItem("lastConnectedWallet", JSON.stringify(updatedWallets));
  };

  const removeFromLocalStorage = (connectorId: string) => {
    const existingWallets = JSON.parse(
      localStorage.getItem("lastConnectedWallet") || "[]"
    );
    const updatedWallets = existingWallets.filter(
      (wallet: any) => wallet.id !== connectorId
    );
    localStorage.setItem("lastConnectedWallet", JSON.stringify(updatedWallets));
  };

  const handleConnect = async (connector: any) => {
    try {
      await connect({ connector });

      if (address) {
        const walletData = {
          id: connector.id,
          name: connector.name,
          address: address,
          type: "EVM",
          img: `https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${connector.name
            .toLowerCase()
            .replace(/wallet$/i, "")
            .replace(" ", "")}/icon.svg`,
        };

        dispatch(addConnectedWallet(walletData));

        saveToLocalStorage(walletData);

        setLocalConnectedWallets((prev: any) => [
          ...prev.filter((wallet: any) => wallet.id !== connector.id),
          walletData,
        ]);
      }
    } catch (error) {
      console.error(`Failed to connect to wallet: ${connector.name}`, error);
    }
  };

  const handleDisconnect = async (connectorId: string) => {
    try {
      await disconnectAsync();
      setLocalConnectedWallets((prev: any) =>
        prev.filter((wallet: any) => wallet.id !== connectorId)
      );

      removeFromLocalStorage(connectorId);

      const walletToRemove = connectedWallets.find(
        (wallet: any) => wallet.id === connectorId
      );

      if (walletToRemove) {
        dispatch(removeConnectedWallet(walletToRemove.address));
      }
    } catch (error) {
      console.error(`Failed to disconnect wallet: ${connectorId}`, error);
    }
  };

  useEffect(() => {
    if (isConnected && activeConnector && address) {
      const walletData = {
        id: activeConnector.id,
        name: activeConnector.name,
        address: address,
        type: "EVM",
        img: `https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${activeConnector.name
          .toLowerCase()
          .replace(/wallet$/i, "")
          .replace(" ", "")}/icon.svg`,
      };

      // Update local state
      setLocalConnectedWallets((prev: any) => [
        ...prev.filter((wallet: any) => wallet.id !== activeConnector.id),
        walletData,
      ]);

      // Save to LocalStorage
      saveToLocalStorage(walletData);

      // Dispatch Redux action to update wallet list
      dispatch(addConnectedWallet(walletData));
    }
  }, [isConnected, activeConnector, address, dispatch]);

  useEffect(() => {
    // Auto-reconnect
    const savedWallets = JSON.parse(
      localStorage.getItem("lastConnectedWallet") || "[]"
    );
    if (!autoReconnectTriggered && savedWallets.length > 0) {
      savedWallets.forEach((savedWallet: any) => {
        const lastConnector = connectors.find(
          (connector) => connector.id === savedWallet.id
        );
        if (lastConnector) {
          dispatch(addConnectedWallet(savedWallet));

          setLocalConnectedWallets((prev: any) => [
            ...prev.filter((wallet: any) => wallet.id !== lastConnector.id),
            savedWallet,
          ]);

          handleConnect(lastConnector);
        }
      });
      setAutoReconnectTriggered(true);
    }
  }, [connectors, autoReconnectTriggered]);

  useEffect(() => {
    const savedWallets = JSON.parse(
      localStorage.getItem("lastConnectedWallet") || "[]"
    );
    if (savedWallets.some((wallet: any) => wallet.id === "walletConnect")) {
      const updatedWallets = savedWallets.filter(
        (wallet: any) => wallet.id !== "walletConnect"
      );
      localStorage.setItem(
        "lastConnectedWallet",
        JSON.stringify(updatedWallets)
      );
    }
  }, []);

  return (
    <>
      {uniqueConnectors.map((connector) => {
        const isWalletConnected = localConnectedWallets.some(
          (wallet: any) => wallet.id === connector.id
        );

        return (
          <ButtonWallet
            key={connector.id}
            onClick={() => {
              if (isWalletConnected) {
                handleDisconnect(connector.id);
              } else {
                handleConnect(connector);
              }
            }}
          >
            <figure>
              <img
                src={`https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${connector.name
                  .toLowerCase()
                  .replace(/wallet$/i, "")
                  .replace(" ", "")}/icon.svg`}
                alt="icon"
              />
            </figure>
            <p>{connector.name.replace(/wallet$/i, "")}</p>
            <WalletConnectedTag
              className={isWalletConnected ? "connected" : "normal"}
            >
              {isWalletConnected ? "Connected" : "Not connect"}
            </WalletConnectedTag>
          </ButtonWallet>
        );
      })}
    </>
  );
};

export default EVMWalletList;
