import styled from "styled-components";

export const InputContainer = styled.div`
  .ant-input-password {
    input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      padding: 16px 50px 16px 24px;
    }
    .ant-input-suffix {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 24px;
    }
  }
  input,
  .ant-input-affix-wrapper {
    width: 100%;
    padding: 10px 24px;
    color: #939393;
    font-size: 24px;
    font-weight: 500;
    border-radius: 9px;
    border: 1px solid transparent;
    background-color: transparent;
    &.ant-input-outlined {
      background-color: transparent;
      border: none;
      border-radius: unset;
      &:focus,
      &:focus-within {
        border-color: transparent;
        box-shadow: unset !important;
      }
    }
    &:hover {
      border-color: transparent;
    }
    &::placeholder {
      color: #989898;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: #989898;
    }
    .ant-input-suffix {
      svg {
        path {
          fill: #fff;
        }
      }
    }
    .ant-input-clear-icon {
      display: flex;
    }
  }
  @media screen and (max-width: 767px) {
    input,
    .ant-input-affix-wrapper {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
`;
