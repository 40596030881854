import { createSlice, Dispatch } from "@reduxjs/toolkit";

const initialState = {
  nativeTokens: [],
};

const nativeTokenSlice = createSlice({
  name: "nativeTokens",
  initialState,
  reducers: {
    setNativeTokens(state, action) {
      state.nativeTokens = action.payload;
    },
  },
});

export const { setNativeTokens } = nativeTokenSlice.actions;

export const fetchAndStoreNativeTokens =
  (data: any) => async (dispatch: Dispatch) => {
    try {
      const nativeTokens = data.map((chain: any) => {
        const feeAsset = chain.feeAssets[0];
        return {
          blockchain: chain.name,
          symbol: feeAsset.symbol,
          logo: chain.logo,
          decimals: chain.defaultDecimals,
          address: feeAsset.address || null,
        };
      });
      dispatch(setNativeTokens(nativeTokens));
    } catch (error) {
      console.error("Error processing native tokens:", error);
    }
  };

const nativeTokenReducer = nativeTokenSlice.reducer;
export default nativeTokenReducer;
