import { TonConnectUIProvider } from "@tonconnect/ui-react";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { useEffect, useMemo } from "react";
import LayoutDashboard from "./Layout/Dashboard";
import Swap from "./page/Swap";
import Farm from "./page/Farm";
import Launchpad from "./page/Launchpad";
import Liquidity from "./page/Liquidity";
import Community from "./page/Community";
import { store } from "./store/store";
import { Provider } from "react-redux";
import WebApp from "@twa-dev/sdk";
import IDODetail from "./page/Launchpad/Detail";
import AddLiquidity from "./page/Liquidity/Add";
import CreatePoolLiquidity from "./page/Liquidity/CreatePool";
import LiquidityDetails from "./page/Liquidity/Details";
import LayoutLiquidity from "./Layout/Liquidity";
import ChartCommon from "./page/Chart";
import Kibble from "./page/Kibble";
import LeaderBoard from "./page/LeaderBoard";
import BridgeV2 from "./page/Bridgev2";
import { createAppKit } from "@reown/appkit/react";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import {
  AppKitNetwork,
  avalanche,
  bsc,
  mainnet,
  optimism,
  arbitrum,
} from "@reown/appkit/networks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  SolflareWalletAdapter,
  PhantomWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WagmiProvider } from "wagmi";
import {
  coinbaseWallet,
  metaMask,
  walletConnect,
  injected,
} from "@wagmi/connectors";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { mainnet as mainnetStarknet } from "@starknet-react/chains";
import {
  StarknetConfig,
  publicProvider,
  argent,
  braavos,
  voyager,
} from "@starknet-react/core";
import { CosmosProvider } from "@cosmostation/use-wallets";

global.Buffer = Buffer;
global.process = process;

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    WebApp.ready();
  }, []);

  const queryClient = new QueryClient();
  const networks: any = [
    bsc as AppKitNetwork,
    avalanche,
    mainnet,
    optimism,
    arbitrum,
  ];
  const projectId = "c84624b4b35e21d900b3f8f307122687";
  const wagmiAdapter = new WagmiAdapter({
    projectId,
    networks,
    storage: null,
    multiInjectedProviderDiscovery: false,
    connectors: [
      injected({
        shimDisconnect: false,
      }),
      coinbaseWallet(),
      metaMask(),
      walletConnect({
        projectId,
        qrModalOptions: {
          themeVariables: {
            "--wcm-z-index": "9999",
          },
        },
      }),
    ],
  });

  const metadata = {
    name: "Kibble",
    description: "",
    url: "",
    icons: [""],
  };

  createAppKit({
    adapters: [wagmiAdapter],
    networks: networks,
    metadata: metadata,
    projectId,
    themeMode: "dark",
    features: {
      swaps: false,
      analytics: false,
      onramp: false,
      socials: false,
      emailShowWallets: false,
      email: false,
    },
  });

  // Solana
  const endpoint = useMemo(() => clusterApiUrl("mainnet-beta"), []);
  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
          <QueryClientProvider client={queryClient}>
            <CosmosProvider>
              <StarknetConfig
                autoConnect={false}
                chains={[mainnetStarknet]}
                provider={publicProvider()}
                connectors={[argent(), braavos()]}
                explorer={voyager}
              >
                <Provider store={store}>
                  <ContextProvider>
                    <TonConnectUIProvider manifestUrl="https://app.kibble.exchange/static/tonconnect-manifest.json">
                      <Toaster
                        position="top-right"
                        reverseOrder={false}
                        toastOptions={{
                          style: {
                            fontFamily: "Syne",
                          },
                        }}
                      />
                      <Routes>
                        <Route element={<LayoutDashboard />}>
                          <Route index path="swap" element={<Swap />} />
                          <Route
                            index
                            path="leaderboard"
                            element={<LeaderBoard />}
                          />
                          <Route index path="chart" element={<ChartCommon />} />
                          <Route path="staking" element={<Farm />} />
                          <Route path="bridge" element={<BridgeV2 />} />
                          <Route path="launchpad" element={<Launchpad />} />
                          <Route path="kibble" element={<Kibble />} />
                          <Route path="launchpad/:id" element={<IDODetail />} />
                          <Route path="liquidity" element={<LayoutLiquidity />}>
                            <Route index element={<Liquidity />} />
                            <Route path="provide" element={<AddLiquidity />} />
                            <Route
                              path="details/:id"
                              element={<LiquidityDetails />}
                            />
                            <Route
                              path="init"
                              element={<CreatePoolLiquidity />}
                            />
                          </Route>
                          <Route
                            path="community-tool"
                            element={<Community />}
                          />
                        </Route>
                        <Route path="*" element={<Navigate to="/swap" />} />
                      </Routes>
                    </TonConnectUIProvider>
                  </ContextProvider>
                </Provider>
              </StarknetConfig>
            </CosmosProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
