import { useContext } from "react";
import { ContextProviderWrapper } from "../../../../components/Context";
import { ButtonCommon } from "../../../../Layout/styled";
import {
  ConfirmBlock,
  ConfirmBlockDetail,
  ConfirmBlockMain,
  ConfirmBuyContainer,
  ConfirmBuyDashed,
  ConfirmRow,
  RouterMain,
} from "./styled";
import { formatNumber } from "../../../../utils/convertNumber";
import { Spin } from "antd";
import CrossDetailItem from "./Item";
interface ConfirmInterface {
  dataConfirm: any;
  routerDetail: any;
  handleRequestTransaction: any;
  isLoadingBuy: any;
  stepLogs: any;
  loadingSwap: any;
}
const ConfirmBuyModal = ({
  dataConfirm,
  routerDetail,
  handleRequestTransaction,
  isLoadingBuy,
  stepLogs,
  loadingSwap,
}: ConfirmInterface) => {
  const { theme, isMobile } = useContext(ContextProviderWrapper)!;
  const calculateArrivalTime = () => {
    const now = new Date();
    const arrival = new Date(now);
    arrival.setSeconds(arrival.getSeconds() + routerDetail.totalTime);
    const isTomorrow = arrival.getDate() !== now.getDate();
    const timeString = arrival.toTimeString().split(" ")[0];

    return isTomorrow ? `Tomorrow, ${timeString}` : `Today, ${timeString}`;
  };
  const formatToTwoDecimals = (number: number) => {
    return Math.floor(number * 100) / 100;
  };
  return (
    <ConfirmBuyContainer className={theme}>
      <ConfirmBuyDashed />
      <ConfirmBlock>
        <ConfirmRow>
          <p className="text-left">ID Request</p>
          <span className="text-right">{dataConfirm?.requestId}</span>
        </ConfirmRow>
        <ConfirmRow>
          <p className="text-left">Exchange rate</p>
          <span className="text-right">{calculateArrivalTime()}</span>
        </ConfirmRow>
      </ConfirmBlock>
      <ConfirmBlockMain className={`${theme} main-router`}>
        <div className="block-heading">
          <div className="swap-summary">
            <div>
              <div>
                <p>
                  Cost: ${formatToTwoDecimals(routerDetail.totalNetworkFee)}
                </p>
              </div>
            </div>
            <div>
              <p>Time: {routerDetail.totalTime} sec</p>
            </div>
          </div>
          <div className="list-tag">
            {dataConfirm?.tags?.map((item: any, index: number) => (
              <div
                className={`tag tag-${item?.value?.toLowerCase()}`}
                key={index}
              >
                {item?.label}
              </div>
            ))}
          </div>
        </div>
        <RouterMain>
          <div className="block-from">
            <div className="block-logo">
              <img
                src={dataConfirm?.swaps?.[0]?.from?.logo}
                alt="Logo Token"
                loading="lazy"
                className="logo-main"
                width={36}
                height={36}
              />
              <img
                src={dataConfirm?.swaps?.[0]?.from?.blockchainLogo}
                alt="Logo Chain"
                loading="lazy"
                className="chain-logo"
                width={18}
                height={18}
              />
            </div>
            <div className="block-price">
              <span className="token">
                {formatToTwoDecimals(
                  Number(
                    formatNumber(
                      dataConfirm?.swaps?.[0]?.fromAmount,
                      dataConfirm?.swaps?.[0]?.from?.decimals
                    )
                  )
                )}
              </span>
              <span className="price">
                {/* {dataConfirm?.swaps?.[0]?.from?.symbol} */}$
                {formatToTwoDecimals(
                  Number(
                    formatNumber(
                      dataConfirm?.swaps?.[0]?.fromAmount,
                      dataConfirm?.swaps?.[0]?.from?.decimals
                    )
                  ) * dataConfirm?.swaps?.[0]?.from?.usdPrice
                )}
              </span>
            </div>
          </div>
          <div className="line">
            <img
              src={`${
                !isMobile
                  ? "/assets/images/bridge/icn_line_06.svg"
                  : "/assets/images/bridge/icn_line_08.svg"
              }`}
              width={300}
              height={6}
              loading="lazy"
              alt="Line"
              className="road-line"
            />
          </div>
          <div className="block-from">
            <div className="block-logo">
              <img
                src={
                  dataConfirm?.swaps?.length > 1
                    ? dataConfirm?.swaps?.at(-1)?.to?.logo
                    : dataConfirm?.swaps?.[0]?.to?.logo
                }
                alt="Logo Token"
                loading="lazy"
                className="logo-main"
                width={36}
                height={36}
              />
              <img
                src={
                  dataConfirm?.swaps?.length > 1
                    ? dataConfirm?.swaps?.at(-1)?.to?.blockchainLogo
                    : dataConfirm?.swaps?.[0]?.to?.blockchainLogo
                }
                alt="Logo Chain"
                loading="lazy"
                className="chain-logo"
                width={18}
                height={18}
              />
            </div>
            <div className="block-price">
              <span className="token">
                {formatToTwoDecimals(
                  Number(
                    dataConfirm?.swaps?.length > 1
                      ? dataConfirm?.swaps?.at(1)?.toAmount
                      : dataConfirm?.swaps?.[0]?.toAmount
                  )
                )}
              </span>
              <span className="price">
                {/* {dataConfirm?.swaps?.length > 1
                  ? dataConfirm?.swaps?.at(1)?.to?.symbol
                  : dataConfirm?.swaps?.[0]?.to?.symbol} */}
                $
                {formatToTwoDecimals(
                  dataConfirm?.swaps?.at(1)?.toAmount *
                    dataConfirm?.swaps?.at(1)?.to?.usdPrice
                )}
              </span>
            </div>
          </div>
        </RouterMain>
      </ConfirmBlockMain>
      <ConfirmBlockDetail className={theme}>
        <h3 className="title-detail">
          <span>Cross Details</span>
        </h3>
        {dataConfirm?.swaps?.map((item: any, index: number) => {
          return (
            <CrossDetailItem
              key={index}
              index={index}
              stepLogs={stepLogs}
              item={item}
              dataConfirm={dataConfirm}
              formatToTwoDecimals={formatToTwoDecimals}
            />
          );
        })}
      </ConfirmBlockDetail>
      <ButtonCommon
        disabled={loadingSwap}
        className="button-buy"
        onClick={() => handleRequestTransaction()}
      >
        {loadingSwap ? <Spin size="small" /> : <p>Confirm & Swap</p>}
      </ButtonCommon>
    </ConfirmBuyContainer>
  );
};

export default ConfirmBuyModal;
