import { SettingsContainer } from "./styled";
import percent from "../../../assets/Dashboard/Common/percent.svg";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import {
  SettingBody,
  SettingSwapHeader,
  SettingSwapLimit,
  SettingSwapList,
  SettingSwapWrapper,
} from "../../Swap/Setting/styled";
import InputCommon from "../../../components/Common/Input";
import { ButtonCommon, SwitchCommon } from "../../../Layout/styled";
import { Switch } from "antd";
import { ConfirmSwapButtons } from "../../Swap/Confirm/styled";
// import SettingsBridge from "./Bridge";
// import SettingsSwap from "./Swap";
// import CustomToken from "./CustomToken";

interface SettingsInterface {
  setSlippageTolerance: any;
  slippageTolerance: any;
  setOpenModal: any;
}
const SettingsModalBridge = ({
  setSlippageTolerance,
  slippageTolerance,
  setOpenModal,
}: SettingsInterface) => {
  const { theme } = useContext(ContextProviderWrapper)!;
  const [switchState, setSwitchState] = useState(false);

  const optionData = [0.5, 1, 3];

  const handleSwitch = (value: any) => {
    setSwitchState(value);
    if (switchState) {
    }
  };
  const handleChangeImpact = (e: any) => {
    setSlippageTolerance(e.target.value);
    localStorage.setItem("slippage", e.target.value);
  };
  useEffect(() => {
    if (!localStorage.getItem("slippage")) {
      localStorage.setItem("slippage", "3");
    }
  }, []);

  return (
    <SettingsContainer>
      <SettingSwapWrapper className={theme}>
        <SettingSwapHeader className={theme}>
          <p>Slippage tolerance per swap</p>
        </SettingSwapHeader>
        <SettingBody>
          <InputCommon
            value={slippageTolerance}
            defaultValue={1}
            suffix={<img src={percent} alt="percent" />}
            onChange={handleChangeImpact}
            placeHolder={"Customs"}
          />
          <SettingSwapList className={theme}>
            {optionData.map((item: any, index: number) => {
              return (
                <li
                  className={
                    String(item) === String(slippageTolerance) ||
                    String(item) === localStorage.getItem("slippage")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setSlippageTolerance(item);
                    localStorage.setItem("slippage", item);
                  }}
                  key={index}
                >
                  {item}%
                </li>
              );
            })}
          </SettingSwapList>
        </SettingBody>
        {/* <SettingsBridge /> */}
        {/* <SettingsSwap /> */}
        {/* <CustomToken /> */}
        <SettingSwapLimit className={theme}>
          <p>Unlimited Approval</p>
          <SwitchCommon className={theme}>
            <Switch checked={switchState} onChange={handleSwitch} />
          </SwitchCommon>
        </SettingSwapLimit>
        <ConfirmSwapButtons>
          <ButtonCommon
            style={{
              width: "100%",
            }}
            onClick={() => setOpenModal(false)}
          >
            <p>Save changes</p>
          </ButtonCommon>
        </ConfirmSwapButtons>
      </SettingSwapWrapper>
    </SettingsContainer>
  );
};

export default SettingsModalBridge;
