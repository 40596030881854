import { useState } from "react";
import EVMWalletList from "./EVM";
import SolanaWalletList from "./Solana";
import { WalletListStyle } from "./styled";
import TonWalletList from "./Ton";
import StarknetWalletList from "./Starknet";
import ConsmosWalletList from "./Cosmos";

function WalletConnectBlock({ searchText, selectFilterType }: any) {
  const [localWalletSolana, setLocalWalletSolana] = useState<
    Record<string, { img: string; name: string; address: string | null }>
  >({});
  const [localWalletCosmos, setLocalWalletCosmos] = useState<
    Record<string, { img: string; name: string; address: string | null }>
  >({});
  const [localWalletEVM, setLocalWalletEVM] = useState<
    { id: string; name: string; address: string }[]
  >([]);
  return (
    <WalletListStyle>
      {selectFilterType.value === "All" || selectFilterType.value === "EVM" ? (
        <EVMWalletList
          searchText={searchText}
          localConnectedWallets={localWalletEVM}
          setLocalConnectedWallets={setLocalWalletEVM}
        />
      ) : null}

      {selectFilterType.value === "All" ||
      selectFilterType.value === "Non EVM" ||
      selectFilterType.value === "Solana" ? (
        <SolanaWalletList
          searchText={searchText}
          localConnectedWallets={localWalletSolana}
          setLocalConnectedWallets={setLocalWalletSolana}
        />
      ) : null}

      {selectFilterType.value === "All" ||
      selectFilterType.value === "Non EVM" ? (
        <>
          <TonWalletList searchText={searchText} />
          <StarknetWalletList searchText={searchText} />
        </>
      ) : null}

      {selectFilterType.value === "All" ||
      selectFilterType.value === "Cosmos" ? (
        <>
          <ConsmosWalletList
            searchText={searchText}
            localConnectedWallets={localWalletCosmos}
            setLocalConnectedWallets={setLocalWalletCosmos}
          />
        </>
      ) : null}
    </WalletListStyle>
  );
}

export default WalletConnectBlock;
