import { useSelector } from "react-redux";
import { ChooseChainContainer } from "./styled";
import { Controller, useForm } from "react-hook-form";
import { ChooseTokenSearch } from "../../../../../Swap/ChooseToken/styled";
import InputCommon from "../../../../../../components/Common/Input";
import clear from "../../../../../../assets/Dashboard/Common/clear.svg";
import search_icon from "../../../../../../assets/Dashboard/Common/search_icon.svg";

type FormValuesProps = {
  searchText: string;
};

const CustomChooseChain = ({ chainSelected, setChainSelected }: any) => {
  const listChain = useSelector((state: any) => state.token.nativeTokens);
  const defaultValues = {
    searchText: "",
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: "onChange",
  });

  const { control, watch } = methods;

  const watchSearchText = watch("searchText");

  const filteredTokens = listChain.filter((token: any) => {
    const blockChain = token.blockchain || "";
    const tokenSymbol = token.symbol || "";
    return (
      blockChain.toLowerCase().includes(watchSearchText.toLowerCase()) ||
      tokenSymbol.toLowerCase().includes(watchSearchText.toLowerCase())
    );
  });

  return (
    <>
      <ChooseTokenSearch>
        <Controller
          name="searchText"
          control={control}
          render={({ field }: any) => (
            <InputCommon
              {...field}
              placeHolder="Search chain name"
              name="searchText"
              allowClear={{ clearIcon: <img src={clear} alt="icon" /> }}
              prefix={<img src={search_icon} alt="icon" />}
            />
          )}
        />
        <p>
          <span>Chain</span>
        </p>
      </ChooseTokenSearch>
      <ChooseChainContainer>
        {filteredTokens.map((item: any, index: any) => {
          return (
            <div
              className={item.blockchain === chainSelected ? "active" : ""}
              key={index}
              onClick={() => {
                setChainSelected(item.blockchain);
              }}
            >
              <figure>
                <img
                  width={30}
                  height={30}
                  src={item.logo}
                  alt="icon"
                  loading="lazy"
                />
              </figure>
              <p>{item.blockchain}</p>
            </div>
          );
        })}
      </ChooseChainContainer>
    </>
  );
};

export default CustomChooseChain;
