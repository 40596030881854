import { useAccount, useConnect } from "@starknet-react/core";
import { useMemo } from "react";
import StarknetItem from "./Item";

const StarknetWalletList = ({ searchText }: any) => {
  const { connectors } = useConnect();
  const { address } = useAccount();
  const filteredConnectors = useMemo(() => {
    if (!searchText) {
      return connectors;
    }
    return connectors.filter((connector: any) =>
      connector.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, connectors]);

  const urlImg = (url: any) => {
    return `https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${url}/icon.svg`;
  };

  return (
    <>
      {filteredConnectors?.length > 0 ? (
        filteredConnectors.map((connector: any, index: number) => {
          return (
            <StarknetItem
              key={index}
              connector={connector}
              urlImg={urlImg}
              address={address}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default StarknetWalletList;
