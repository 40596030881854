import styled from "styled-components";

export const ConfirmBuyContainer = styled.div`
  color: #fff;
  font-family: "Rethink Sans";
  font-size: 15px;
  &.light {
    color: #000;
  }
  .ant-spin-dot-item {
    background-color: #fff;
  }
`;
export const ConfirmBuyDashed = styled.div`
  border: 1px dashed rgba(179, 179, 179, 0.5);
`;
export const ConfirmBlock = styled.div`
  padding-top: 10px;
  margin-bottom: 10px;
`;
export const ConfirmRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
  .text-left {
    color: #656565;
  }
  .text-right {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
  }
`;
export const ConfirmBlockMain = styled.div`
  background-color: rgb(63, 63, 63);
  padding: 11px;
  border-radius: 6px;
  margin-bottom: 12px;
  width: 100%;
  &.main-router {
    width: calc(100% - 16px);
  }
  &.light {
    background-color: #f3f4f8;
    .line {
      border-bottom-color: #000;
      &:after {
        border-color: #000;
      }
    }
  }
  .block-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #525252;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .block-heading-detail {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .sub-title {
    font-size: 14px;
    font-weight: 500;
  }
  .swap-summary {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      border-radius: 19px;
      background: #d6d6d6;
      padding: 2px 8px;
      font-size: 11px;
      font-weight: 500;
      color: #000;
    }
  }
  .list-tag {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .tag {
    border-radius: 19px;
    background: #e4c7ee;
    padding: 2px 8px;
    color: #8000a7;
    font-size: 11px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -0.33px;
    &.tag-recommended {
      background: #c8eec7;
      color: #00a703;
    }
    &.tag-fastest {
      background: #c7d7ee;
      color: #007af5;
    }
    &.is-hight {
      background: #ffd8d8;
      color: #f50000;
    }
  }
  @media screen and (max-width: 767.98px) {
    .swap-summary {
      gap: 4px;
      p {
        font-size: 9px;
      }
    }
    .list-tag {
      gap: 4px;
      .tag {
        font-size: 9px;
      }
    }
    .sub-title {
      font-size: 12px;
    }
  }
`;
export const RouterMain = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  &.has-logo-intermediary {
    align-items: flex-start;
    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .block-from {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .block-logo {
    position: relative;
    .logo-main {
      border-radius: 50%;
    }
    .chain-logo {
      position: absolute;
      bottom: 4px;
      right: -4px;
    }
  }
  .block-price {
    span {
      display: block;
      line-height: 1;
    }
    .token {
      font-size: 17.159px;
      font-weight: 600;
    }
    .price {
      color: #6b6b6b;
      font-size: 13.727px;
    }
  }
  .line {
    width: 100%;
    position: relative;
    img {
      border: solid 1px #000;
      border-radius: 31px;
    }
    .road-line {
      border: 0;
      width: 100%;
    }
    .road-line-detail {
      width: 100%;
      border: 0;
    }
  }
  .block-internal-image {
    display: flex;
    align-items: center;
  }
`;
export const ConfirmBlockDetail = styled.div`
  &.light {
    .title-detail {
      span {
        background-color: #fff;
      }
    }
    ${ConfirmBlockMain} {
      background-color: #f3f4f8;
    }
    .line {
      border-bottom-color: #000;
      &:after {
        border-color: #000;
      }
    }
  }
  .title-detail {
    text-align: center;
    position: relative;
    margin-bottom: 12px;
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border: dashed 1px #656565;
      width: 100%;
    }
    span {
      color: #656565;
      position: relative;
      z-index: 1;
      padding: 0 10px;
      background-color: #1c1c1e;
    }
  }
`;
export const ContentRouterDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  &.router-detail {
    position: relative;
    &:not(:last-child) {
      &:before {
        position: absolute;
        content: "";
        top: 64%;
        left: 11px;
        width: 5px;
        height: 65px;
        background: url("/assets/images/bridge/icn_line_03.svg") center /
          contain no-repeat;
      }
    }
    ${ConfirmBlockMain} {
      padding: 7px;
      margin-bottom: 8px;
    }
    .block-heading {
      padding-bottom: 8px;
      margin-bottom: 8px;
    }
    .block-logo {
      .logo-main {
        width: 32px;
        height: 32px;
      }
      .chain-logo {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
export const ContentRouterNumber = styled.p`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
  border-radius: 59px;
  flex-shrink: 0;
  span {
    color: #535353;
    text-align: center;
    font-weight: 600;
    line-height: 125%;
    position: relative;
    top: 2px;
  }
`;
export const LoggerContainer = styled.div`
  margin-top: 10px;
`;
export const LoggerCheck = styled.div`
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  border-radius: 8px;
  .step-log-container {
    color: #000;
  }
  .transaction-processing {
    margin-top: 10px;
    p {
      color: #000;
    }
    .ant-spin {
      margin-left: 5px;
    }
    .ant-spin-dot-item {
      background-color: #000;
    }
  }
  .transaction-success {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: #000;
    }
  }
`;
