import CustomAddToken from "./Add";

const CustomToken = () => {
  return (
    <div
      style={{
        marginTop: "20px",
      }}
    >
      <CustomAddToken />
    </div>
  );
};

export default CustomToken;
