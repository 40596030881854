import { useAccount, useConnect, useDisconnect } from "@starknet-react/core";
import { ButtonWallet, WalletConnectedTag } from "../../styled";
import { useDispatch } from "../../../../../store/store";
import {
  addConnectedWallet,
  removeConnectedWallet,
} from "../../../../../store/features/accountSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const StarknetItem = ({ connector, urlImg, address }: any) => {
  const { connector: connectorItem } = useAccount();
  const { connectAsync }: any = useConnect();
  const { disconnectAsync } = useDisconnect();
  const dispatch = useDispatch();
  const connectedWallets = useSelector(
    (state: any) => state.accountSlice.referrals
  );
  const [isConnected, setIsConnected] = useState(false);

  const handleConnect = async (connector: any) => {
    await connectAsync({ connector });
    setIsConnected(true);
  };

  useEffect(() => {
    if (connector?.id === connectorItem?.id && isConnected) {
      const walletData = {
        img: urlImg(connector?.id.toLowerCase()),
        name: connector.name,
        address,
        type: "STARKNET",
      };

      dispatch(addConnectedWallet(walletData));
    }
  }, [address, isConnected, dispatch]);

  const handleDisconnect = async (connectorName: string) => {
    try {
      await disconnectAsync();
      setIsConnected(false);
      const wallet = connectedWallets.find(
        (w: any) => w.name === connectorName
      );

      if (wallet) {
        dispatch(removeConnectedWallet(wallet.address));
      }
    } catch (error) {
      console.error("Failed to disconnect wallet:", error);
    }
  };

  return (
    <ButtonWallet
      onClick={() => {
        if (isConnected) {
          handleDisconnect(connector.name);
        } else {
          handleConnect(connector);
        }
      }}
    >
      <figure>
        <img
          src={urlImg(connector?.id.toLowerCase())}
          alt="icon"
          loading="lazy"
        />
      </figure>
      <p>{connector?.name}</p>
      <WalletConnectedTag className={isConnected ? "connected" : "normal"}>
        {isConnected ? "Connected" : "Not connect"}
      </WalletConnectedTag>
    </ButtonWallet>
  );
};

export default StarknetItem;
